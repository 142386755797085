.wrapper {
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.info {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #616161;
}

.label {
  display: block;
  font-size: 12px;
  color: #616161;
}

.toggleButtons {
  display: flex;
  margin-top: 8px;
  margin-bottom: 14px;

  & > button {
    height: 32px;
    padding: 0;
    font-size: 14px;
    color: #555;
    background-color: white;
    border: 1px solid #DEDEDE;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left-color: white;
    }
  }
}

.toggleButtons2Items {
  & > button {
    &:first-child {
      width: 40%;
    }

    &:last-child {
      width: 60%;
    }
  }
}

.toggleButtons3Items {
  flex-wrap: wrap;

  & > button {
    width: 50%;
    max-width: 50%;

    &:first-child {
      border-bottom-left-radius: 0;
    }

    &:nth-child(2) {
      border-top-right-radius: 4px;
    }

    &:last-child {
      width: 100%;
      max-width: 100%;
      border-left: 1px solid #dedede;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 4px;
    }
  }
}

.toggleButtonsDegradationFragmentation {
  & > button {
    height: 42px;
  }
}

.levelControls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.checkboxRoot {
  margin-right: 18px;
  margin-bottom: 6px;

  & > span:first-child {
    padding: 4px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.checkboxLabel {
  font-size: 12px;
  color: #616161;
}

.actionButton {
  transition: all 200ms ease-in-out;
}

.descriptionText {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
}

.formControlSelectInput {
  margin-top: 8px;
  margin-bottom: 14px;
}
