@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
.TabList_navList__2rloQ {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 4px;
  background-color: #F5F5F5;
  border-radius: 6px; }

.TabList_navListSmall__2AJut .TabList_navListItem__1Scvk {
  font-size: 11px;
  font-weight: 500; }

.TabList_navListFullWidth__8r1CO {
  width: 100%; }

.TabList_navListItem__1Scvk {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  padding: 8px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .07em;
  color: #8E8E8E;
  background-color: #F5F5F5;
  border-radius: 6px;
  transition: all 200ms ease-in-out;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .TabList_navListItem__1Scvk {
      font-size: 14px; } }

.TabList_disabledItem__36NPB {
  pointer-events: none;
  opacity: .5; }

.ActiveOptionsList_list__2wFV_ {
  padding: 0;
  margin-top: 6px;
  margin-bottom: 0; }

.ActiveOptionsList_listItem__1rcNQ {
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 8px;
  margin-bottom: 4px;
  background-color: #F7F7F7;
  border: 1px solid #DEDEDE;
  border-radius: 4px; }

.ActiveOptionsList_listItemText__vbq6W {
  flex: 1 1;
  font-size: 12px; }

.ActiveOptionsList_listItemRemoveButton__1RfQH {
  padding: 2px;
  margin-left: 4px; }
  .ActiveOptionsList_listItemRemoveButton__1RfQH svg {
    font-size: 18px; }

.CustomSelect_inputLabel__20BZg {
  -webkit-transform: translate(8px, -4px) scale(0.75) !important;
          transform: translate(8px, -4px) scale(0.75) !important; }

.CARBox_formWrapper__2dGiz {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 16px; }
  .CARBox_formWrapper__2dGiz label {
    background-color: #F7F7F7; }

.CARBox_formControl__tWHbX {
  width: 100%; }
  .CARBox_formControl__tWHbX input {
    font-size: 12px; }

.CARBox_searchIconButton__2NzIt {
  padding: 8px;
  margin-left: auto; }
  .CARBox_searchIconButton__2NzIt svg {
    font-size: 20px;
    color: #777; }

.CARBox_loadingProgressWrapper__1X3WO {
  display: inline-flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin-right: 8px; }

.CARBox_actionWrapper__1Fg7K {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px; }

.CARBox_searchButton__3hOJh svg {
  margin-right: 6px; }

.CARBox_removeButton__2D9IS {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px; }
  .CARBox_removeButton__2D9IS svg {
    margin-right: 6px;
    font-size: 18px; }

.GeometryControl_wrapper__1Yva8 {
  margin-bottom: 24px; }

.GeometryControl_formControl__24k_D {
  margin-top: 32px; }

.GeometryControl_actionButtonActive__3KVgG {
  color: white !important;
  background-color: #199272; }
  .GeometryControl_actionButtonActive__3KVgG:hover {
    color: white;
    background-color: #199272; }

.GeometryControl_infoText__3JeoV,
.GeometryControl_infoTextSecondary__1yXm9,
.GeometryControl_infoTextError__17qDF {
  display: block;
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 12px;
  color: #155724;
  line-height: 1.4;
  background-color: #d4edda;
  border-radius: 5px; }

.GeometryControl_infoTextSecondary__1yXm9 {
  color: #0c5460;
  background-color: #d1ecf1; }

.GeometryControl_infoTextError__17qDF {
  color: #721c24;
  background-color: #f8d7da; }

.GeometryControl_buttonsWrapper__NLYH7 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px; }
  .GeometryControl_buttonsWrapper__NLYH7 > button {
    width: 48%;
    color: #5c5c5c; }

.BaseForm_wrapper__3eOYV {
  padding-bottom: 12px;
  margin-bottom: 24px;
  margin-top: 4px;
  border-bottom: 1px solid #EBEAEF; }

.BaseForm_wrapperInline__2IdWk {
  display: flex; }
  .BaseForm_wrapperInline__2IdWk .BaseForm_formControl__3Kiop {
    flex: 1 1;
    margin-right: 16px;
    margin-bottom: 0; }
    .BaseForm_wrapperInline__2IdWk .BaseForm_formControl__3Kiop:last-child {
      margin-right: 0; }

.BaseForm_formControl__3Kiop {
  padding-top: 16px;
  margin-bottom: 16px; }

.BaseForm_radioGroup__2O0_O {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 4px; }
  .BaseForm_radioGroup__2O0_O > label {
    width: 50%;
    margin-right: 0;
    margin-bottom: 4px; }

.BaseForm_radioLabel__3qMZO {
  font-size: 13px; }

.BaseForm_radioInput__1Pne0 {
  padding: 4px; }

.BaseForm_checkboxRoot__3APgB {
  margin-top: -12px;
  margin-bottom: 6px; }

.BaseForm_checkboxControl__2ggWh {
  margin-top: -12px; }

.BaseForm_checkboxLabel__Xdn7x {
  font-size: 12px; }

.BaseForm_tooltipWrapper__16SVX {
  display: flex;
  align-items: center; }
  .BaseForm_tooltipWrapper__16SVX svg {
    margin-left: 4px;
    color: #777;
    font-size: 16px; }

.BaseForm_yearRangeFormControl__2ILjP {
  margin-top: 16px;
  margin-bottom: 16px; }

.BaseForm_groupList__2Ld9y {
  background-color: #fff; }

.InfoTooltip_icon__2el5S {
  margin-left: 4px;
  color: #777;
  font-size: 16px;
  cursor: help; }

.BaseClassTreeNodesControl_baseClassInfo__2hZ7l {
  display: block;
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 32px;
  font-size: 12px;
  color: #155724;
  line-height: 1.4;
  background-color: #d4edda;
  border-radius: 5px; }

.BaseClassTreeNodesControl_formControl__3j7x9 {
  margin-bottom: 16px; }

.DegradationIsolationClassTreeNodesControl_baseClassInfo__27PBf,
.DegradationIsolationClassTreeNodesControl_baseClassInfoSecondary__2cxrx {
  display: block;
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 32px;
  font-size: 12px;
  color: #155724;
  line-height: 1.4;
  background-color: #d4edda;
  border-radius: 5px; }

.DegradationIsolationClassTreeNodesControl_baseClassInfoSecondary__2cxrx {
  color: #383d41;
  background-color: #e2e3e5; }

.DegradationIsolationClassTreeNodesControl_formControl__M1jIo {
  margin-bottom: 32px; }

.DegradationIsolationClassTreeNodesControl_actionWrapper__1hsje {
  display: flex;
  justify-content: space-between;
  margin-top: -12px; }

.ClassTreesControl_wrapper__DoynA {
  position: relative; }

.ClassTreesControl_title__2n1vX {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.ClassTreesControl_info__3rLpq {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #616161; }

.ClassTreesControl_label__1FfuQ {
  display: block;
  font-size: 12px;
  color: #616161; }

.ClassTreesControl_toggleButtons__b9g6M {
  display: flex;
  margin-top: 8px;
  margin-bottom: 14px; }
  .ClassTreesControl_toggleButtons__b9g6M > button {
    height: 32px;
    padding: 0;
    font-size: 14px;
    color: #555;
    background-color: white;
    border: 1px solid #DEDEDE;
    cursor: pointer; }
    .ClassTreesControl_toggleButtons__b9g6M > button:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .ClassTreesControl_toggleButtons__b9g6M > button:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left-color: white; }

.ClassTreesControl_toggleButtons2Items__guL13 > button:first-child {
  width: 40%; }

.ClassTreesControl_toggleButtons2Items__guL13 > button:last-child {
  width: 60%; }

.ClassTreesControl_toggleButtons3Items__19WZA {
  flex-wrap: wrap; }
  .ClassTreesControl_toggleButtons3Items__19WZA > button {
    width: 50%;
    max-width: 50%; }
    .ClassTreesControl_toggleButtons3Items__19WZA > button:first-child {
      border-bottom-left-radius: 0; }
    .ClassTreesControl_toggleButtons3Items__19WZA > button:nth-child(2) {
      border-top-right-radius: 4px; }
    .ClassTreesControl_toggleButtons3Items__19WZA > button:last-child {
      width: 100%;
      max-width: 100%;
      border-left: 1px solid #dedede;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 4px; }

.ClassTreesControl_toggleButtonsDegradationFragmentation__J0q9X > button {
  height: 42px; }

.ClassTreesControl_levelControls__3nxFg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; }

.ClassTreesControl_checkboxRoot__2BmrX {
  margin-right: 18px;
  margin-bottom: 6px; }
  .ClassTreesControl_checkboxRoot__2BmrX > span:first-child {
    padding: 4px; }
  .ClassTreesControl_checkboxRoot__2BmrX:last-child {
    margin-right: 0; }

.ClassTreesControl_checkboxLabel__8_VZ0 {
  font-size: 12px;
  color: #616161; }

.ClassTreesControl_actionButton__3LmhK {
  transition: all 200ms ease-in-out; }

.ClassTreesControl_descriptionText__Pfyop {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px; }

.ClassTreesControl_formControlSelectInput__16wNN {
  margin-top: 8px;
  margin-bottom: 14px; }

.ClassLegendList_classListItemBase__1qHxS {
  border-bottom: 1px solid #EBEAEF; }

.ClassLegendList_classListItemHeader__BS1VC {
  display: flex;
  align-items: center;
  padding: 2px 4px; }

.ClassLegendList_classListItemCheckControl__3fD2b {
  padding: 4px; }
  .ClassLegendList_classListItemCheckControl__3fD2b svg {
    font-size: 14px; }

.ClassLegendList_classListItemTitle__x3Hdd {
  flex: 1 1;
  margin-left: 4px;
  margin-right: 4px;
  color: #616161; }

.ClassLegendList_classListItemContentControl__3mZBI {
  padding: 4px; }
  .ClassLegendList_classListItemContentControl__3mZBI svg {
    font-size: 22px; }

.ClassLegendList_classListItemInfoControl__2GxJL {
  padding: 2px;
  margin-right: 5px; }
  .ClassLegendList_classListItemInfoControl__2GxJL svg {
    font-size: 16px; }

.ClassLegendList_classNode__1gMyE {
  padding: 0;
  margin: 0; }

.ClassLegendList_classChildNode__N8RPi {
  margin-left: 28px;
  padding-bottom: 4px; }
  .ClassLegendList_classChildNode__N8RPi .ClassLegendList_classListItemHeader__BS1VC {
    padding: 0 4px 0 0; }
  .ClassLegendList_classChildNode__N8RPi .ClassLegendList_classListItemInfoControl__2GxJL {
    margin-right: 5px; }
  .ClassLegendList_classChildNode__N8RPi .ClassLegendList_classListItemBase__1qHxS {
    border-bottom: none; }

.GenericClasses_wrapper__2Xjmt {
  position: relative; }

.GenericClasses_loadingWrapper__G1DJp {
  display: flex;
  justify-content: center;
  margin-top: 16px; }

.GeospatialObjects_wrapper__Bpqne {
  position: relative;
  margin-bottom: 32px; }

.GeospatialObjects_classListItemBase__23xqx {
  border-bottom: 1px solid #DEDEDE; }

.GeospatialObjects_classListItemHeader__25OKP {
  display: flex;
  align-items: center;
  padding: 2px 4px; }

.GeospatialObjects_classListItemCheckControl__20ntZ {
  padding: 4px; }
  .GeospatialObjects_classListItemCheckControl__20ntZ svg {
    font-size: 14px; }

.GeospatialObjects_classListItemTitle__311u3 {
  flex: 1 1;
  margin-left: 4px;
  margin-right: 4px;
  color: #333; }

.GeospatialObjects_classListItemContentControl__6r9AE {
  padding: 4px; }
  .GeospatialObjects_classListItemContentControl__6r9AE svg {
    font-size: 22px; }

.GeospatialObjects_classListItemInfoControl__6hsee {
  padding: 2px;
  margin-right: 5px; }
  .GeospatialObjects_classListItemInfoControl__6hsee svg {
    font-size: 16px; }

.GeospatialObjects_classNode__2P2_x {
  padding: 0;
  margin: 0; }

.GeospatialObjects_classChildNode__3uSC5 {
  margin-left: 28px;
  padding-bottom: 4px; }
  .GeospatialObjects_classChildNode__3uSC5 .GeospatialObjects_classListItemHeader__25OKP {
    padding: 0 4px 0 0; }
  .GeospatialObjects_classChildNode__3uSC5 .GeospatialObjects_classListItemInfoControl__6hsee {
    margin-right: 5px; }
  .GeospatialObjects_classChildNode__3uSC5 .GeospatialObjects_classListItemBase__23xqx {
    border-bottom: none; }

.GeospatialObjects_title__18c80 {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.GeospatialObjects_info__3FX4x {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #616161; }

.GeospatialObjects_label__XEYCt {
  display: block;
  font-size: 12px;
  color: #616161; }

.GeospatialObjects_toggleButtons__2yl4O {
  display: flex;
  margin-top: 8px;
  margin-bottom: 14px; }
  .GeospatialObjects_toggleButtons__2yl4O > button {
    height: 32px;
    padding: 0;
    font-size: 14px;
    color: #555;
    background-color: white;
    border: 1px solid #DEDEDE;
    cursor: pointer; }
    .GeospatialObjects_toggleButtons__2yl4O > button:first-child {
      width: 40%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .GeospatialObjects_toggleButtons__2yl4O > button:last-child {
      width: 60%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left-color: white; }

.GeospatialObjects_levelControls__3Zx1s {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; }

.GeospatialObjects_checkboxRoot__31Z35 {
  margin-right: 20px;
  margin-bottom: 6px; }
  .GeospatialObjects_checkboxRoot__31Z35 > span:first-child {
    padding: 4px; }
  .GeospatialObjects_checkboxRoot__31Z35:last-child {
    margin-right: 0; }

.GeospatialObjects_checkboxLabel__2eh1h {
  font-size: 12px;
  color: #616161; }

.GeospatialObjects_actionButton__NgFXk {
  transition: all 200ms ease-in-out; }

.GeospatialObjects_descriptionText__1yjcJ {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px; }

.GeospatialObjects_actionBoxControlButton__3ZCYn {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 4px; }
  .GeospatialObjects_actionBoxControlButton__3ZCYn svg {
    font-size: 16px; }

.TransitionClasses_title__3dBRZ {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.TransitionClasses_classList__2qIA_ {
  padding: 0;
  margin: 0; }

.TransitionClasses_classListLevel2__31-mL .TransitionClasses_classListItemBase__tnZW7,
.TransitionClasses_classListLevel3__1Shzz .TransitionClasses_classListItemBase__tnZW7,
.TransitionClasses_classListLevel4__3HYgk .TransitionClasses_classListItemBase__tnZW7 {
  border-bottom: none; }

.TransitionClasses_classListItemBase__tnZW7 {
  border-bottom: 1px solid #DEDEDE; }

.TransitionClasses_classListItemHeader__3vdpK {
  display: flex;
  align-items: center;
  padding: 6px 4px; }

.TransitionClasses_classListItemCheckControl__3BBMs {
  padding: 4px; }
  .TransitionClasses_classListItemCheckControl__3BBMs svg {
    font-size: 18px; }

.TransitionClasses_classListItemTitle__298nL {
  flex: 1 1;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 13px;
  color: #555; }

.TransitionClasses_classListItemContentControl__tz3TU {
  padding: 4px; }
  .TransitionClasses_classListItemContentControl__tz3TU svg {
    font-size: 22px; }

.TransitionClasses_classListItemInfoControl__1jWEb {
  padding: 2px;
  margin-right: 9px; }
  .TransitionClasses_classListItemInfoControl__1jWEb svg {
    font-size: 16px; }

.TransitionClasses_classListLevel2__31-mL {
  margin-left: 28px;
  padding-bottom: 4px; }
  .TransitionClasses_classListLevel2__31-mL .TransitionClasses_classListItemHeader__3vdpK {
    padding: 0 4px 0 0; }
  .TransitionClasses_classListLevel2__31-mL .TransitionClasses_classListItemCheckControl__3BBMs svg {
    font-size: 17px; }
  .TransitionClasses_classListLevel2__31-mL .TransitionClasses_classListItemTitle__298nL {
    font-size: 12px; }

.TransitionClasses_classListLevel3__1Shzz {
  margin-left: 24px; }
  .TransitionClasses_classListLevel3__1Shzz .TransitionClasses_classListItemHeader__3vdpK {
    padding: 0 4px 0 0; }
  .TransitionClasses_classListLevel3__1Shzz .TransitionClasses_classListItemCheckControl__3BBMs svg {
    font-size: 16px; }
  .TransitionClasses_classListLevel3__1Shzz .TransitionClasses_classListItemTitle__298nL {
    font-size: 11px; }

.TransitionClasses_classListLevel4__3HYgk {
  margin-left: 32px; }
  .TransitionClasses_classListLevel4__3HYgk .TransitionClasses_classListItemHeader__3vdpK {
    padding: 0; }
  .TransitionClasses_classListLevel4__3HYgk .TransitionClasses_classListItemCheckControl__3BBMs svg {
    font-size: 14px; }
  .TransitionClasses_classListLevel4__3HYgk .TransitionClasses_classListItemTitle__298nL {
    font-size: 10px; }

.TransitionClasses_checkboxLabel__3xZs- {
  font-size: 14px; }

.QualityClasses_title__2uKtU {
  margin-top: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.QualityClasses_text__3RT4h {
  font-size: 14px;
  line-height: 1.4; }

.QualityClasses_legendGradient__wzR2p {
  position: relative;
  max-width: 100%;
  height: 32px;
  background: linear-gradient(to right, #d73027 0%, #d73027 13.04%, #f46d43 21.73%, #fdae61 30.43%, #fee08b 39.13%, #ffffbf 47.82%, #d9ef8b 56.52%, #a6d96a 65.21%, #66bd63 73.91%, #1a9850 82.6%, #006837 100%); }
  .QualityClasses_legendGradient__wzR2p::before, .QualityClasses_legendGradient__wzR2p::after {
    position: absolute;
    top: 34px;
    font-size: 14px; }
  .QualityClasses_legendGradient__wzR2p::before {
    content: '0';
    left: 0; }
  .QualityClasses_legendGradient__wzR2p::after {
    content: '23';
    right: 0; }

.TranslateOptionsBox_boxWrapper__1ShrY {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24); }

.TranslateOptionsBox_button__21_Bz {
  width: 162px;
  padding: 12px 16px;
  border: none;
  font-size: 14px;
  color: #555;
  text-align: left;
  background-color: white;
  border-bottom: 1px solid #EBEAEF;
  transition: all 200ms ease-in-out;
  cursor: pointer; }
  .TranslateOptionsBox_button__21_Bz:hover {
    background-color: #f7f7f7; }
  .TranslateOptionsBox_button__21_Bz:last-child {
    border-bottom: none; }
  @media screen and (max-width: 960px) {
    .TranslateOptionsBox_button__21_Bz {
      padding: 8px 12px;
      font-size: 12px; } }

.InstitutionalMenu_title__3Gx6v {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  margin: 0;
  color: #555;
  background-color: #F7F7F7;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase; }

.InstitutionalMenu_navListWrapper__3EKN4 {
  overflow-y: scroll; }

.InstitutionalMenu_navList__3-VpA {
  padding: 0;
  margin: 16px; }
  .InstitutionalMenu_navList__3-VpA > li {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px; }
    .InstitutionalMenu_navList__3-VpA > li > span {
      display: block;
      color: #444;
      font-size: 14px;
      cursor: default;
      text-transform: uppercase; }
    .InstitutionalMenu_navList__3-VpA > li > ul {
      padding-left: 16px;
      margin-top: 8px; }
      .InstitutionalMenu_navList__3-VpA > li > ul > li {
        display: block;
        margin-top: 8px;
        margin-bottom: 8px; }
        .InstitutionalMenu_navList__3-VpA > li > ul > li > a {
          display: block;
          color: #444;
          font-size: 12px;
          text-transform: uppercase;
          transition: color 200ms ease-in-out; }
          .InstitutionalMenu_navList__3-VpA > li > ul > li > a:hover {
            color: #16a085; }

.LoginForm_formWrapper__OLIBc {
  margin: 0; }

.LoginForm_textInfo__2FuLh {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.LoginForm_formControl__3VJs8 {
  margin-bottom: 32px; }

.LoginForm_actionsWrapper__2SJUJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -6px; }

.LoginForm_helperLink__2mJTf {
  font-size: 14px;
  color: #333;
  transition: all 200ms ease-in-out; }
  .LoginForm_helperLink__2mJTf:hover {
    cursor: pointer;
    color: #16a085; }

.SignUpForm_formWrapper__IlxrJ {
  margin: 0; }

.SignUpForm_title__RSGy4 {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  font-weight: 500;
  color: #545454; }

.SignUpForm_textInfo__3CFgE {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.SignUpForm_formControl__3khwG {
  margin-bottom: 32px; }

.SignUpForm_actionsWrapper__za6ZS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -6px; }

.SignUpForm_helperLink__2JlbV {
  font-size: 14px;
  color: #333; }

.SignUpForm_successText__37mab {
  position: relative;
  padding: 12px;
  margin-top: -16px;
  font-size: 14px;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px; }

.MapForm_title__1Lk2A {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  font-weight: 500;
  color: #545451; }

.MapForm_formWrapper__Q70Ao {
  margin: 0; }

.MapForm_textInfo__3UedW {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.MapForm_formControl__C_bb5 {
  margin-bottom: 32px; }

.MapForm_actionsWrapper__1MRhL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -6px; }

.MapForm_checkboxRoot__2Wc1g {
  margin-top: -24px;
  margin-bottom: 24px; }

.MapForm_checkboxLabel__3Trwg {
  font-size: 14px; }

.MapList_mapList__3q6EI {
  padding-left: 0;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #EBEAEF; }

.MapList_mapItem__2Z7ny {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #DEDEDE; }
  .MapList_mapItem__2Z7ny:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none; }

.MapList_mapItemImage__2qUx0 {
  max-width: 80px;
  height: auto;
  margin-right: 16px; }

.MapList_mapItemTextContent__p8Th4 {
  display: flex;
  flex-direction: column;
  flex: 1 1; }

.MapList_mapItemTitle__F4Kup {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #545451; }

.MapList_mapItemDescription__2hvM8 {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  color: #555; }

.MapList_mapItemActionsContent__3RUBw {
  margin-left: 12px; }

.MapList_mapItemViewButton__2KPAd {
  padding: 3px;
  margin-left: 0; }
  .MapList_mapItemViewButton__2KPAd:first-child {
    margin-right: 2px; }
  .MapList_mapItemViewButton__2KPAd svg {
    font-size: 18px; }

.MapList_mapItemViewButtonSecondary__1Klu3 svg {
  font-size: 17px;
  fill: #999; }

.MapEditDialog_dialogWrapper__KUWAP {
  padding: 16px; }

.MapEditDialog_header__3GwnH {
  position: relative; }

.MapEditDialog_title__3vwZC {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #444; }

.MapEditDialog_closeButton__3uw4U {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px; }
  .MapEditDialog_closeButton__3uw4U svg {
    font-size: 18px; }

.MapEditDialog_content__3yTL_ {
  margin-top: 24px; }

.MapEditDialog_contentDescription__1eDKV {
  font-size: 14px;
  line-height: 1.4; }
  .MapEditDialog_contentDescription__1eDKV a {
    color: #16a085; }

.MapEditDialog_actions__LQWmm {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px; }

.PasswordRecoveryForm_formWrapper__2gMvT {
  margin: 0; }

.PasswordRecoveryForm_title__ol0AM {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  font-weight: 500;
  color: #545454; }

.PasswordRecoveryForm_textInfo__25ute {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.PasswordRecoveryForm_formControl__WU0Eh {
  margin-bottom: 16px; }

.PasswordRecoveryForm_actionsWrapper__22Qo1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px; }

.PasswordRecoveryForm_helperLink__367p2 {
  font-size: 14px;
  color: #333; }

.PasswordRecoveryForm_successText__1otci {
  position: relative;
  padding: 12px;
  margin-top: -16px;
  font-size: 14px;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px; }

.AccountMenu_titleWrapper__1_H52 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 16px;
  background-color: #F7F7F7; }

.AccountMenu_title__1C6sm {
  margin: 0;
  color: #555;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase; }

.AccountMenu_wrapper__3FzZR {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 320px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  color: #444;
  background-color: white;
  transition: all .5s ease;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  box-shadow: 0 2px 4px rgba(195, 195, 195, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02); }
  @media screen and (max-width: 768px) {
    .AccountMenu_wrapper__3FzZR {
      z-index: 4000;
      width: 100%;
      padding-top: 50px;
      overflow-y: scroll; } }

.AccountMenu_isVisible__1lFRJ {
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.AccountMenu_toggleButton__2Lbl6 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: #555;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  cursor: pointer;
  transition: all 200ms ease-in-out; }
  .AccountMenu_toggleButton__2Lbl6:hover {
    background-color: #616161; }
  .AccountMenu_toggleButton__2Lbl6 svg {
    font-size: 32px;
    color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .AccountMenu_toggleButton__2Lbl6 {
      display: none; } }

.AccountMenu_header__2-y3L {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px; }

.AccountMenu_myMapsContentWrapper__F2rSU {
  padding: 20px 16px; }

.AccountMenu_translation__1_dit {
  font-size: 14px;
  margin-right: 16px; }
  .AccountMenu_translation__1_dit > a {
    display: inline-block;
    margin-left: 8px;
    color: #444;
    transition: color 200ms ease-in-out; }
    .AccountMenu_translation__1_dit > a:hover {
      color: #16a085; }

.AccountMenu_subtitle__1f0aa {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  margin-top: 0;
  margin-bottom: 16px;
  background-color: #F7F7F7;
  font-size: 16px; }

.AccountMenu_content__1wUmU {
  flex: 1 1;
  padding-bottom: 16px;
  overflow-y: scroll; }

.AccountMenu_infoText__2yOQj {
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  line-height: 1.4;
  text-align: center; }
  .AccountMenu_infoText__2yOQj a {
    cursor: pointer;
    color: #16a085; }

.AccountMenu_navList__1fys6 {
  padding: 0;
  margin: 0 16px; }
  .AccountMenu_navList__1fys6 > li {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px; }
    .AccountMenu_navList__1fys6 > li > span {
      display: block;
      color: #444;
      font-size: 14px;
      cursor: default;
      text-transform: uppercase; }
    .AccountMenu_navList__1fys6 > li > ul {
      padding-left: 16px;
      margin-top: 8px; }
      .AccountMenu_navList__1fys6 > li > ul > li {
        display: block;
        margin-top: 8px;
        margin-bottom: 8px; }
        .AccountMenu_navList__1fys6 > li > ul > li > a {
          display: block;
          color: #444;
          font-size: 12px;
          text-transform: uppercase;
          transition: color 200ms ease-in-out; }
          .AccountMenu_navList__1fys6 > li > ul > li > a:hover {
            color: #16a085; }

.AccountMenu_userInfo__2h9cc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  background-color: #F7F7F7;
  border-top: 1px solid #DEDEDE; }
  .AccountMenu_userInfo__2h9cc > span:first-child {
    display: inline-flex;
    align-items: center; }
    .AccountMenu_userInfo__2h9cc > span:first-child svg {
      margin-right: 8px;
      font-size: 20px; }
    .AccountMenu_userInfo__2h9cc > span:first-child span {
      font-size: 14px; }
  .AccountMenu_userInfo__2h9cc > button {
    padding: 2px; }
    .AccountMenu_userInfo__2h9cc > button svg {
      font-size: 20px; }

.AccountMenu_hideMenuButton__c7NH4 {
  padding: 0; }
  .AccountMenu_hideMenuButton__c7NH4 svg {
    font-size: 24px;
    color: #717171; }

.DegradationViewModeControl_wrapper__1t9Ur {
  padding: 0 16px;
  margin-bottom: 24px;
  margin-top: 4px;
  border-bottom: 1px solid #EBEAEF; }

.DegradationViewModeControl_wrapperInline__18OnP {
  display: flex; }
  .DegradationViewModeControl_wrapperInline__18OnP .DegradationViewModeControl_formControl__2IHrb {
    flex: 1 1;
    margin-right: 16px;
    margin-bottom: 0; }
    .DegradationViewModeControl_wrapperInline__18OnP .DegradationViewModeControl_formControl__2IHrb:last-child {
      margin-right: 0; }

.DegradationViewModeControl_formControl__2IHrb {
  padding-top: 16px;
  margin-bottom: 16px; }

.DegradationViewModeControl_radioGroup__1iIdx {
  flex-direction: row;
  margin-bottom: 16px;
  margin-left: 4px; }
  .DegradationViewModeControl_radioGroup__1iIdx > label {
    margin-right: 16px; }
    .DegradationViewModeControl_radioGroup__1iIdx > label:last-child {
      margin-right: 0; }

.DegradationViewModeControl_radioLabel__CUg25 {
  font-size: 13px; }

.DegradationViewModeControl_radioInput__btkXr {
  padding: 4px; }

.DegradationViewModeControl_checkboxRoot__3F9_T {
  margin-top: -12px;
  margin-bottom: 6px; }

.DegradationViewModeControl_checkboxControl__2m0YZ {
  margin-top: -12px; }

.DegradationViewModeControl_checkboxLabel__2QcPk {
  font-size: 12px; }

.DegradationViewModeControl_tooltipWrapper__2yoRP {
  display: flex;
  align-items: center; }
  .DegradationViewModeControl_tooltipWrapper__2yoRP svg {
    margin-left: 4px;
    color: #777;
    font-size: 16px; }

.DegradationViewModeControl_yearRangeFormControl__2E1Fy {
  margin-top: 16px;
  margin-bottom: 16px; }

.DegradationViewModeControl_groupList__RmEJM {
  background-color: #fff; }

.DegradationVectorControl_control__3Rl0E {
  position: relative;
  margin-bottom: 18px; }

.DegradationVectorControl_groupList__2wKR_ {
  background-color: #fff; }

.DegradationVectorControl_removeButton__cu8yf {
  position: absolute;
  top: 10px;
  right: 32px;
  padding: 3px; }
  .DegradationVectorControl_removeButton__cu8yf svg {
    font-size: 18px; }

:root {
  /* Spacing */
  --smaller-spacing: .5rem;
  --small-spacing: .75rem;
  --base-spacing: 1rem;
  --medium-spacing: 1.5rem;
  --large-spacing: 2rem;
  --larger-spacing: 3rem;
  --extra-larger-spacing: 4rem; }

.Header_wrapper__1e-Og {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000; }
  @media screen and (max-width: 768px) {
    .Header_wrapper__1e-Og {
      z-index: 5000; } }
  @media screen and (max-width: 480px) {
    .Header_wrapper__1e-Og {
      width: 100%; } }

@media screen and (max-width: 480px) {
  .Header_wrapperHidden__GVN77 {
    z-index: 1000;
    -webkit-transform: translate3d(-100%, 0px, 0px);
            transform: translate3d(-100%, 0px, 0px); } }

.Header_wrapperIframeMode__39Pzo .Header_logoBox__2Xuwa,
.Header_wrapperIframeMode__39Pzo .Header_menuContent__3dW_o {
  display: none; }

.Header_wrapperIframeMode__39Pzo .Header_headerWrapper__2m_a2 {
  width: 60px; }

.Header_headerWrapper__2m_a2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;
  display: flex;
  width: 400px;
  background-color: white;
  transition: all .5s ease;
  -webkit-transform: translate3d(0%, 0px, 0px);
          transform: translate3d(0%, 0px, 0px);
  box-shadow: 4px 1px 4px rgba(195, 195, 195, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 768px) {
    .Header_headerWrapper__2m_a2 {
      z-index: 5000; } }
  @media screen and (max-width: 480px) {
    .Header_headerWrapper__2m_a2 {
      width: 100%; } }

.Header_headerWrapperHidden__3zyDf {
  -webkit-transform: translate3d(-100%, 0px, 0px);
          transform: translate3d(-100%, 0px, 0px); }

.Header_logoBox__2Xuwa {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 70px;
  background-color: white;
  transition: all .5s ease;
  -webkit-transform: translate3d(60px, 0, 0);
          transform: translate3d(60px, 0, 0);
  border-bottom-right-radius: 6px; }
  .Header_logoBox__2Xuwa img {
    display: inline-block;
    max-width: 180px;
    height: auto; }
    @media screen and (max-width: 480px) {
      .Header_logoBox__2Xuwa img {
        max-width: 160px; } }
  @media screen and (max-width: 480px) {
    .Header_logoBox__2Xuwa {
      width: calc(100% - 60px); } }

.Header_logoBoxCollapsed__1lGrf {
  -webkit-transform: translate3d(0%, 0px, 0px);
          transform: translate3d(0%, 0px, 0px);
  box-shadow: 3px 3px 4px rgba(195, 195, 195, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 480px) {
    .Header_logoBoxCollapsed__1lGrf {
      width: 340px;
      -webkit-transform: translate3d(100%, 0px, 0px);
              transform: translate3d(100%, 0px, 0px); } }

.Header_versionTag__2SCyI {
  position: absolute;
  top: 25px;
  right: 32px;
  display: inline-block;
  padding: 2px 6px;
  color: white;
  font-size: 9px;
  font-weight: 700;
  background-color: #84caa0;
  border-radius: 6px; }
  @media screen and (max-width: 480px) {
    .Header_versionTag__2SCyI {
      right: 40px;
      font-size: 8px; } }

.Header_newTag__18z-s,
.Header_betaTag__3z3ky {
  position: absolute;
  top: 17px;
  right: 38px;
  display: inline-block;
  padding: 2px 4px;
  color: white;
  font-size: 7px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #E5412E;
  border-radius: 4px; }
  @media screen and (max-width: 480px) {
    .Header_newTag__18z-s,
    .Header_betaTag__3z3ky {
      right: 44px;
      font-size: 7px; } }

.Header_betaTag__3z3ky {
  background-color: #f39c12 !important; }

.Header_menuBarListItemOldTag__2YNlE {
  position: absolute;
  top: 4px;
  right: 3px;
  display: inline-block;
  padding: 1px 4px;
  color: white;
  font-size: 7px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #16a085 !important;
  border-radius: 4px; }
  @media screen and (max-width: 480px) {
    .Header_menuBarListItemOldTag__2YNlE {
      font-size: 6px; } }

.Header_menuBarListItemNewTag__3SQLi,
.Header_menuBarListItemBetaTag__iBcAw {
  position: absolute;
  top: 4px;
  right: 3px;
  display: inline-block;
  padding: 1px 4px;
  color: white;
  font-size: 7px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #E5412E !important;
  border-radius: 4px; }
  @media screen and (max-width: 480px) {
    .Header_menuBarListItemNewTag__3SQLi,
    .Header_menuBarListItemBetaTag__iBcAw {
      font-size: 6px; } }

.Header_menuBarListItemBetaTag__iBcAw {
  background-color: #f39c12 !important; }

.Header_toggleButton__233g6 {
  position: absolute;
  top: 50%;
  right: -16px;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border-radius: 6px;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  transition: all .5s ease; }
  .Header_toggleButton__233g6:hover svg {
    fill: #8E8E8E; }
  .Header_toggleButton__233g6 svg {
    font-size: 26px;
    fill: #A2A2A2;
    transition: fill 200ms ease-in-out, -webkit-transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease, -webkit-transform .5s ease; }
  @media screen and (max-width: 480px) {
    .Header_toggleButton__233g6 {
      right: 16px; } }

.Header_toggleButtonExpand__2XD1L svg {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

@media screen and (max-width: 480px) {
  .Header_toggleButtonExpand__2XD1L {
    right: -16px; } }

.Header_menuBar__vI2mT {
  position: relative;
  z-index: 3000;
  width: 60px;
  background-color: white;
  border-right: 1px solid #EBEAEF; }

.Header_menuContent__3dW_o,
.Header_linksContent__y7J-N {
  flex-direction: column;
  width: 340px;
  padding-bottom: 50px;
  padding-top: 70px; }
  @media screen and (max-width: 480px) {
    .Header_menuContent__3dW_o,
    .Header_linksContent__y7J-N {
      width: calc(100% - 60px); } }

.Header_linksContent__y7J-N {
  position: absolute;
  top: 0;
  left: 60px;
  bottom: 0;
  background-color: white;
  z-index: 2000;
  padding-bottom: 0;
  transition: all .5s ease;
  -webkit-transform: translateX(-340px);
          transform: translateX(-340px); }
  @media screen and (max-width: 480px) {
    .Header_linksContent__y7J-N {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); } }

.Header_linksContentIsVisible__1HQk- {
  -webkit-transform: translateX(0px);
          transform: translateX(0px); }

.Header_linksContentIframeMode__35Gfa {
  padding-top: 0; }

.Header_navOpenButton__3-GcZ {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  padding: 0;
  border: none;
  color: #777;
  background-color: #ECECEC;
  cursor: pointer;
  transition: all 200ms ease-in-out; }
  .Header_navOpenButton__3-GcZ:hover {
    background-color: #E4E4E4; }

@media screen and (max-width: 480px) {
  .Header_toggleButtonAlone__Ik_8I {
    -webkit-transform: translate3d(50px, 0px, 0px);
            transform: translate3d(50px, 0px, 0px); } }

.Header_topContent__2FcC6 {
  padding: 0 16px 16px; }

.Header_contentWrapper__YhT-7 {
  position: relative;
  flex: 1 1;
  padding: 0 16px;
  overflow-y: scroll; }

.Header_logo__3bgzQ {
  display: block;
  margin-bottom: 6px;
  text-align: center; }
  .Header_logo__3bgzQ img {
    display: inline-block;
    max-width: 180px;
    height: auto; }

.Header_tabContent__3B_La {
  flex: 1 1; }

.Header_statisticsButtonWrapper__3bLak {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px; }

.Header_bottomContent__1_Vov {
  position: absolute;
  bottom: 0;
  left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 340px;
  background-color: #f7f7f7; }

.Header_navItemButtonBeta__18Vd- {
  position: relative; }

.Header_navItemButtonBetaAddon__Ohe1D {
  position: absolute;
  top: 10px;
  right: 6px;
  display: inline-block;
  padding: 2px 4px;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: white;
  background-color: #f39c12;
  border-radius: 2px; }

.Header_activeBlockIconBetaAddon__2nao3 {
  position: absolute;
  top: 10px;
  right: 6px;
  display: inline-block;
  padding: 2px 4px;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: white;
  background-color: #f39c12;
  border-radius: 2px; }
  @media screen and (max-width: 480px) {
    .Header_activeBlockIconBetaAddon__2nao3 {
      right: auto;
      left: 18px; } }

.Header_infrastructureClasses__3dXrw {
  margin-top: 8px;
  margin-bottom: 24px; }

.Header_menuBarList__3OqT8 {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0; }

.Header_menuBarListItem__BaMY2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  background-color: white;
  transition: all 200ms ease-in-out;
  cursor: pointer; }
  @media screen and (max-height: 790px) {
    .Header_menuBarListItem__BaMY2 {
      height: 40px; } }
  @media screen and (max-height: 700px) {
    .Header_menuBarListItem__BaMY2 {
      height: 36px; } }

.Header_menuBarListItemLine__1_GUA {
  position: absolute;
  top: 0;
  left: 0;
  height: 46px;
  width: 0.15rem;
  border-radius: 0.15rem;
  background-color: white;
  transition: all 200ms ease-in-out; }
  @media screen and (max-height: 790px) {
    .Header_menuBarListItemLine__1_GUA {
      height: 40px; } }
  @media screen and (max-height: 700px) {
    .Header_menuBarListItemLine__1_GUA {
      height: 36px; } }

.Header_menuBarListItemButtonIcon__EyaSs {
  max-width: 24px; }

.Header_moduleIcon__1nhee,
.Header_moduleIconDegradation__3fQI1 {
  width: 22px;
  height: 22px;
  fill: #A2A2A2;
  transition: all 200ms ease-in-out; }
  @media screen and (max-height: 790px) {
    .Header_moduleIcon__1nhee,
    .Header_moduleIconDegradation__3fQI1 {
      height: 20px;
      width: 20px; } }
  @media screen and (max-height: 700px) {
    .Header_moduleIcon__1nhee,
    .Header_moduleIconDegradation__3fQI1 {
      height: 18px;
      width: 18px; } }

.Header_menuBarListItemCoverage__2RCR0:hover svg {
  fill: #1a9272; }

.Header_menuBarListItemCoverageIsActive__3z_Lu {
  background-color: #1a9272; }
  .Header_menuBarListItemCoverageIsActive__3z_Lu span {
    background-color: #1a9272; }
  .Header_menuBarListItemCoverageIsActive__3z_Lu svg {
    fill: #1a9272; }

.Header_menuBarListItemCoverageQuality__I3M5B svg {
  width: 31px;
  height: 31px; }
  @media screen and (max-height: 790px) {
    .Header_menuBarListItemCoverageQuality__I3M5B svg {
      width: 24px;
      height: 24px; } }

.Header_menuBarListItemCoverageQuality__I3M5B:hover svg {
  fill: #455a64; }

.Header_menuBarListItemCoverageQualityIsActive__2sjL3 {
  background-color: #455a64; }
  .Header_menuBarListItemCoverageQualityIsActive__2sjL3 span {
    background-color: #455a64; }
  .Header_menuBarListItemCoverageQualityIsActive__2sjL3 svg {
    fill: #455a64; }

.Header_menuBarListItemTrajectories__2tF72:hover svg {
  fill: #7b1fa2; }

.Header_menuBarListItemTrajectoriesIsActive__2Vz5y {
  background-color: #7b1fa2; }
  .Header_menuBarListItemTrajectoriesIsActive__2Vz5y span {
    background-color: #7b1fa2; }
  .Header_menuBarListItemTrajectoriesIsActive__2Vz5y svg {
    fill: #7b1fa2; }

.Header_menuBarListItemQualityOfPastureData__AwF_l:hover svg {
  fill: #e6680f; }

.Header_menuBarListItemQualityOfPastureDataIsActive__2gr3A {
  background-color: #e6680f; }
  .Header_menuBarListItemQualityOfPastureDataIsActive__2gr3A span {
    background-color: #e6680f; }
  .Header_menuBarListItemQualityOfPastureDataIsActive__2gr3A svg {
    fill: #e6680f; }

.Header_menuBarListItemDeforestation__3v-M1:hover svg {
  fill: #cd6133; }

.Header_menuBarListItemDeforestationIsActive__3yRKu {
  background-color: #cd6133; }
  .Header_menuBarListItemDeforestationIsActive__3yRKu span {
    background-color: #cd6133; }
  .Header_menuBarListItemDeforestationIsActive__3yRKu svg {
    fill: #cd6133; }

.Header_menuBarListItemRegeneration__EhqwS:hover svg {
  fill: #24a34e; }

.Header_menuBarListItemRegenerationIsActive__27YEX {
  background-color: #24a34e; }
  .Header_menuBarListItemRegenerationIsActive__27YEX span {
    background-color: #24a34e; }
  .Header_menuBarListItemRegenerationIsActive__27YEX svg {
    fill: #24a34e; }

.Header_menuBarListItemInfrastructure__3dbA6:hover svg {
  fill: #434343; }

.Header_menuBarListItemInfrastructureIsActive__3n3DX {
  background-color: #434343; }
  .Header_menuBarListItemInfrastructureIsActive__3n3DX span {
    background-color: #434343; }
  .Header_menuBarListItemInfrastructureIsActive__3n3DX svg {
    fill: #434343; }

.Header_menuBarListItemIrrigation__2qr08 svg {
  width: 28px;
  height: 28px; }

.Header_menuBarListItemIrrigation__2qr08:hover svg {
  fill: #a606c3; }

.Header_menuBarListItemIrrigationIsActive__3e57v {
  background-color: #a606c3; }
  .Header_menuBarListItemIrrigationIsActive__3e57v span {
    background-color: #a606c3; }
  .Header_menuBarListItemIrrigationIsActive__3e57v svg {
    fill: #a606c3; }

.Header_menuBarListItemFire__32LoY:hover svg {
  fill: #E5412E; }

.Header_menuBarListItemFireIsActive__1Od-w {
  background-color: #E5412E; }
  .Header_menuBarListItemFireIsActive__1Od-w span {
    background-color: #E5412E; }
  .Header_menuBarListItemFireIsActive__1Od-w svg {
    fill: #E5412E; }

.Header_menuBarListItemMining__2dZIb:hover svg {
  fill: #F39C12; }

.Header_menuBarListItemMiningIsActive__2oQm0 {
  background-color: #F39C12; }
  .Header_menuBarListItemMiningIsActive__2oQm0 span {
    background-color: #F39C12; }
  .Header_menuBarListItemMiningIsActive__2oQm0 svg {
    fill: #F39C12; }

.Header_menuBarListItemWater__uvJsl:hover svg {
  fill: #2691D9; }

.Header_menuBarListItemWaterIsActive__29eLB {
  background-color: #2691D9; }
  .Header_menuBarListItemWaterIsActive__29eLB span {
    background-color: #2691D9; }
  .Header_menuBarListItemWaterIsActive__29eLB svg {
    fill: #2691D9; }

.Header_menuBarListItemSentinel__Nw2-F:hover svg {
  fill: #1f2942; }

.Header_menuBarListItemSentinelIsActive__JTIEx {
  background-color: #1f2942; }
  .Header_menuBarListItemSentinelIsActive__JTIEx span {
    background-color: #1f2942; }
  .Header_menuBarListItemSentinelIsActive__JTIEx svg {
    fill: #1f2942; }

.Header_menuBarListItemSoil__2jlkh:hover svg {
  fill: #886956; }

.Header_menuBarListItemSoilIsActive__3QowX {
  background-color: #886956; }
  .Header_menuBarListItemSoilIsActive__3QowX span {
    background-color: #886956; }
  .Header_menuBarListItemSoilIsActive__3QowX svg {
    fill: #886956; }

.Header_menuBarListItemDegradation__1aQ74:hover svg {
  fill: #936327; }

.Header_menuBarListItemDegradationIsActive__Ynaxo {
  background-color: #936327; }
  .Header_menuBarListItemDegradationIsActive__Ynaxo span {
    background-color: #936327; }
  .Header_menuBarListItemDegradationIsActive__Ynaxo svg {
    fill: #936327; }

.Header_menuBarListItemCoralReef__37CHI:hover svg {
  fill: #006F9A; }

.Header_menuBarListItemCoralReefIsActive__3w6_k {
  background-color: #006F9A; }
  .Header_menuBarListItemCoralReefIsActive__3w6_k span {
    background-color: #006F9A; }
  .Header_menuBarListItemCoralReefIsActive__3w6_k svg {
    fill: #006F9A; }

.Header_menuBarBottomContent__1q7Ep {
  position: absolute;
  bottom: 0;
  left: 0; }

.Header_menuButton__1vgrQ {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: white;
  cursor: pointer;
  transition: all 200ms ease-in-out; }
  .Header_menuButton__1vgrQ svg {
    font-size: 24px;
    fill: #A2A2A2;
    transition: all 200ms ease-in-out; }
  .Header_menuButton__1vgrQ:hover svg {
    fill: #8E8E8E; }
  @media screen and (max-height: 790px) {
    .Header_menuButton__1vgrQ {
      height: 40px; }
      .Header_menuButton__1vgrQ svg {
        font-size: 20px; } }

.Header_translateButton__7F8uU {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  background-color: white;
  border: none;
  border-top: 1px solid #EBEAEF;
  transition: all 200ms ease-in-out;
  cursor: pointer; }
  .Header_translateButton__7F8uU:hover {
    background-color: #f7f7f7; }
  .Header_translateButton__7F8uU > span {
    margin-left: 3px;
    font-size: 10px;
    font-weight: 500;
    color: #777; }
  .Header_translateButton__7F8uU > svg {
    font-size: 14px;
    fill: #777; }
    .Header_translateButton__7F8uU > svg:last-child {
      margin-left: auto;
      font-size: 16px; }

.Header_actionButton__3s1Ka {
  color: #5c5c5c; }
  .Header_actionButton__3s1Ka:first-child {
    margin-right: 16px; }
  .Header_actionButton__3s1Ka svg {
    margin-right: 6px;
    font-size: 20px; }

.Header_fireModeContentWrapper__3ReFo {
  position: absolute;
  top: 70px;
  left: 60px;
  bottom: 16px;
  width: 340px;
  overflow-y: scroll;
  padding: 16px; }

.Header_fireModeContent__1jYQF {
  flex-direction: column; }
  @media screen and (max-width: 480px) {
    .Header_fireModeContent__1jYQF {
      width: calc(100% - 60px); } }

.Header_fireModeContentBox__1KYbk,
.Header_fireModeContentBoxExtra__3Z6aw {
  padding: 16px;
  margin-bottom: 16px;
  background: #F5F5F5;
  border-radius: 4px; }

.Header_fireModeContentBoxExtra__3Z6aw {
  color: #856404;
  background-color: #fff3cd; }
  .Header_fireModeContentBoxExtra__3Z6aw .Header_fireModeContentBoxTextWrapper__3w5M2 p {
    margin-top: 0;
    margin-bottom: 0; }

.Header_fireModeContentBoxTitle__3VccS {
  margin-top: 0;
  font-size: 14px;
  font-weight: 500; }

.Header_fireModeContentBoxTextWrapper__3w5M2 p {
  font-size: 13px;
  line-height: 1.4; }
  .Header_fireModeContentBoxTextWrapper__3w5M2 p:last-child {
    margin-bottom: 0; }

.Header_fireModeContentBoxTextWrapper__3w5M2 > ul {
  padding-left: 16px;
  margin-top: 8px;
  margin-bottom: 0; }
  .Header_fireModeContentBoxTextWrapper__3w5M2 > ul > li {
    display: block;
    margin-bottom: 6px;
    font-size: 12px; }

.Header_fireModeContentBoxActions__3W5Mq {
  display: flex;
  justify-content: flex-end; }

.Header_customModeContentBoxButton__2FHYO {
  margin-top: 12px; }

.Header_fireModeContentBoxButton__3GpsP {
  margin-top: 12px;
  background-color: #e74c3c; }
  .Header_fireModeContentBoxButton__3GpsP:hover {
    background-color: #e5412e; }

.Header_infrastructureModeContentBoxButton__59GXb {
  margin-top: 12px;
  background-color: #434343; }
  .Header_infrastructureModeContentBoxButton__59GXb:hover {
    background-color: #555; }

.Header_irrigationModeContentBoxButton__1YXyG {
  margin-top: 12px;
  background-color: #b731ce; }
  .Header_irrigationModeContentBoxButton__1YXyG:hover {
    background-color: #ac2ec2; }

.Header_deforestationModeContentBoxButton__2l1l7 {
  margin-top: 12px;
  background-color: #cd6133; }
  .Header_deforestationModeContentBoxButton__2l1l7:hover {
    background-color: #CD6130; }

.Header_headerImageLogoSentinel__3Qucr {
  width: 180px !important;
  max-width: initial !important; }

.Header_headerImageLogoDegradation__2GNbR {
  width: 196px !important;
  max-width: 100% !important; }

.Header_degradationControls__1QVnH {
  margin-top: 42px; }
  .Header_degradationControls__1QVnH > div {
    margin-bottom: 32px; }
    .Header_degradationControls__1QVnH > div:last-child {
      margin-bottom: 12px; }

.Header_degradationControlsAction__1rZXt {
  display: flex;
  justify-content: flex-end;
  margin-top: -12px; }

.Footer_wrapper__3xouO {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466; }
  @media screen and (max-width: 480px) {
    .Footer_wrapper__3xouO {
      padding: 0 6px; } }

.Footer_versionText__2JHZr {
  font-size: 12px;
  margin-right: 5px;
  color: white; }
  @media screen and (max-width: 768px) {
    .Footer_versionText__2JHZr {
      font-size: 10px; } }
  @media screen and (max-width: 480px) {
    .Footer_versionText__2JHZr {
      font-size: 9px; } }

.Footer_copyText__IoOT4 {
  font-size: 12px;
  color: white; }
  @media screen and (max-width: 768px) {
    .Footer_copyText__IoOT4 {
      font-size: 10px; } }
  @media screen and (max-width: 480px) {
    .Footer_copyText__IoOT4 {
      font-size: 9px; } }

.Footer_alertText__33vwr {
  font-size: 10px;
  color: white; }
  @media screen and (max-width: 768px) {
    .Footer_alertText__33vwr {
      display: none; } }

.Footer_ecostageImage__2_p4l {
  display: block;
  max-width: 86px;
  height: auto; }
  @media screen and (max-width: 768px) {
    .Footer_ecostageImage__2_p4l {
      max-width: 72px; } }
  @media screen and (max-width: 480px) {
    .Footer_ecostageImage__2_p4l {
      max-width: 60px; } }

@media print {
  .Footer_wrapper__3xouO {
    display: none; } }

.WarningNoteDialog_dialogWrapper__2XnOR {
  padding: 16px; }

.WarningNoteDialog_header__nqPh0 {
  position: relative; }

.WarningNoteDialog_title__1isuU {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.WarningNoteDialog_closeButton__3On_I {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px; }
  .WarningNoteDialog_closeButton__3On_I svg {
    font-size: 18px; }

.WarningNoteDialog_content__-r0TE {
  margin-top: 24px; }

.WarningNoteDialog_contentDescription__2DU6n {
  font-size: 14px;
  line-height: 1.4; }
  .WarningNoteDialog_contentDescription__2DU6n a {
    color: #16a085; }

.WarningNoteDialog_actions__1_o7p {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px; }

.BasePage_pageWrapper__616cZ {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }

@media print {
  .BasePage_pageWrapper__616cZ {
    height: auto !important; } }

.BasePage_contentWrapper__1v1xU {
  position: relative;
  flex: 1 1;
  overflow-y: scroll;
  overflow-x: hidden; }
  .BasePage_contentWrapper__1v1xU iframe {
    margin-left: 60px; }

.CARBox_wrapper__3oKkG {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3000;
  transition: all .5s ease;
  -webkit-transform: translate3d(410px, -456px, 0);
          transform: translate3d(410px, -456px, 0); }
  @media screen and (max-width: 960px) {
    .CARBox_wrapper__3oKkG {
      -webkit-transform: translate3d(410px, -456px, 0);
              transform: translate3d(410px, -456px, 0); } }

.CARBox_wrapperWithoutHeader__1mvAR {
  -webkit-transform: translate3d(16px, -456px, 0);
          transform: translate3d(16px, -456px, 0); }
  @media screen and (max-width: 768px) {
    .CARBox_wrapperWithoutHeader__1mvAR {
      -webkit-transform: translate3d(16px, -456px, 0);
              transform: translate3d(16px, -456px, 0); } }

@media screen and (max-width: 768px) {
  .CARBox_wrapperWithBeforeAndAfterMosaic__1TSCM {
    bottom: 24px; } }

.CARBox_controlWrapper__2tnpb {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }

.CARBox_searchButton__aXfe6 {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  border-radius: 6px; }
  .CARBox_searchButton__aXfe6:hover {
    background-color: #F1F1F1; }
  .CARBox_searchButton__aXfe6 svg {
    font-size: 22px;
    fill: #555; }
  @media screen and (max-width: 1024px) {
    .CARBox_searchButton__aXfe6 {
      border-radius: 6px; } }

.CARBox_title__1hXRZ {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px; }

.CARBox_formWrapper__17W7I {
  position: absolute;
  left: 44px;
  bottom: 0;
  width: 432px;
  display: flex;
  align-items: center;
  padding: 26px 10px 12px 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }
  .CARBox_formWrapper__17W7I h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085; }
  .CARBox_formWrapper__17W7I label {
    background-color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .CARBox_formWrapper__17W7I {
      width: 280px;
      padding: 16px 10px 12px 10px; } }

.CARBox_textInfo__3S75g {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.CARBox_formControl__WNcn2 {
  width: 360px;
  margin-right: 12px; }
  .CARBox_formControl__WNcn2 input {
    font-size: 12px; }

.CARBox_searchIconButton__2McjE {
  padding: 8px;
  margin-left: auto; }
  .CARBox_searchIconButton__2McjE svg {
    font-size: 20px;
    color: #777; }

.CARBox_infoIcon__2i4w6 {
  margin-right: 8px;
  font-size: 18px;
  fill: #555;
  cursor: help; }

.CARBox_loadingProgressWrapper__3BEU7 {
  display: inline-flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center; }

.CARBox_carInfoWrapper__16fCz {
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 3000;
  width: 432px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;
  transition: all .5s ease;
  -webkit-transform: translate3d(452px, 0, 0);
          transform: translate3d(452px, 0, 0); }

.CARBox_carInfoWrapperWithoutHeader__RLNuJ {
  -webkit-transform: translate3d(348px, 0, 0);
          transform: translate3d(348px, 0, 0); }

.CARBox_header__2tJAh {
  position: relative; }

.CARBox_title__1hXRZ {
  margin: 0;
  font-size: 16px;
  font-weight: 600; }

.CARBox_closeButton__2vumz {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px; }
  .CARBox_closeButton__2vumz svg {
    font-size: 18px; }

.CARBox_dataList__2rHIn {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 0; }

.CARBox_dataListItem__3jEPx {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 50%; }
  .CARBox_dataListItem__3jEPx:last-child {
    margin-bottom: 0; }

.CARBox_dataListItemLabel__1_Zgt {
  margin-bottom: 4px;
  font-size: 12px;
  color: #777; }

.CARBox_dataListItemValue__3fCEJ {
  font-size: 12px;
  color: #444; }

.ClassLegendDialog_wrapper__UX17J {
  padding: 16px;
  background-color: white;
  position: absolute;
  top: 17px;
  left: 372px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  transition: width 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: width 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important; }
  @media screen and (max-width: 768px) {
    .ClassLegendDialog_wrapper__UX17J {
      width: calc(100% - 20px);
      height: calc(100% - 92px);
      -webkit-transform: translateX(10px);
              transform: translateX(10px); } }

.ClassLegendDialog_wrapperWithHeader__3Kwek {
  left: 452px; }

.ClassLegendDialog_header__27nTB {
  position: relative; }

.ClassLegendDialog_title__azrb5 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600; }

.ClassLegendDialog_closeButton__tAA3m {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px; }
  .ClassLegendDialog_closeButton__tAA3m svg {
    font-size: 18px; }

.ClassLegendDialog_content__2AuRd {
  overflow-y: scroll; }
  .ClassLegendDialog_content__2AuRd p {
    font-size: 14px;
    line-height: 1.4; }

.ClassLegendDialog_contentDescription__2axeV {
  margin-top: 18px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }
  .ClassLegendDialog_contentDescription__2axeV:last-child {
    margin-bottom: 0; }

.ClassLegendDialog_imageList__eNkMt {
  display: flex;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0; }
  .ClassLegendDialog_imageList__eNkMt > li {
    width: 120px;
    margin: 0 16px;
    text-align: center; }
    .ClassLegendDialog_imageList__eNkMt > li > img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 8px; }
    .ClassLegendDialog_imageList__eNkMt > li > span {
      font-size: 12px; }

.CoordinatesBox_wrapper__2wBd1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3000;
  transition: all .5s ease;
  -webkit-transform: translate3d(410px, -66px, 0);
          transform: translate3d(410px, -66px, 0); }
  @media screen and (max-width: 960px) {
    .CoordinatesBox_wrapper__2wBd1 {
      -webkit-transform: translate3d(390px, -66px, 0);
              transform: translate3d(390px, -66px, 0); } }

.CoordinatesBox_wrapperWithoutHeader__2f550 {
  -webkit-transform: translate3d(16px, -66px, 0);
          transform: translate3d(16px, -66px, 0); }
  @media screen and (max-width: 768px) {
    .CoordinatesBox_wrapperWithoutHeader__2f550 {
      -webkit-transform: translate3d(10px, -66px, 0);
              transform: translate3d(10px, -66px, 0); } }

@media screen and (max-width: 768px) {
  .CoordinatesBox_wrapperWithBeforeAndAfterMosaic__3vAtW {
    bottom: 24px; } }

.CoordinatesBox_controlWrapper__1YdxY {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }

.CoordinatesBox_searchButton__35CnU {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  border-right: 1px solid #DADADA;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }
  .CoordinatesBox_searchButton__35CnU:hover {
    background-color: #F1F1F1; }
  .CoordinatesBox_searchButton__35CnU svg {
    font-size: 22px;
    fill: #555; }
  @media screen and (max-width: 1024px) {
    .CoordinatesBox_searchButton__35CnU {
      border-radius: 6px; } }

.CoordinatesBox_title__3qp_J {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px; }

.CoordinatesBox_formWrapper__19YkW {
  position: absolute;
  left: 44px;
  bottom: 42px;
  width: 320px;
  display: flex;
  align-items: center;
  padding: 26px 10px 12px 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }
  .CoordinatesBox_formWrapper__19YkW h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085; }
  .CoordinatesBox_formWrapper__19YkW label {
    background-color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .CoordinatesBox_formWrapper__19YkW {
      width: 280px;
      padding: 16px 10px 12px 10px; } }

.CoordinatesBox_textInfo__3iVGm {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.CoordinatesBox_formControl__ClNMW {
  width: 120px;
  margin-right: 12px; }
  .CoordinatesBox_formControl__ClNMW input {
    text-align: right; }

.CoordinatesBox_searchIconButton__P6KMH {
  padding: 8px;
  margin-left: auto; }
  .CoordinatesBox_searchIconButton__P6KMH svg {
    font-size: 20px;
    color: #777; }

.CoordinatesBox_infoIcon__3v0Xn {
  margin-right: 8px;
  font-size: 18px;
  fill: #555;
  cursor: help; }

.StickyTable_tableWrapper__uzpqT {
  position: relative;
  display: flex;
  background-color: #FAFAFA;
  overflow: scroll; }

.StickyTable_categoriesTitle__36aMz {
  display: block;
  height: 37px;
  min-width: 120px;
  padding-left: 12px;
  line-height: 37px;
  font-size: 12px;
  background-color: #E6E6E6;
  border-bottom: 1px solid #e0e0e0; }

.StickyTable_tableCategoriesList__3GJIc {
  padding-left: 0;
  margin: 0; }
  .StickyTable_tableCategoriesList__3GJIc > li {
    height: 35px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 34px;
    white-space: nowrap;
    font-size: 12px;
    background-color: #F1F1F1;
    border-bottom: 1px solid #e0e0e0; }
  @media screen and (max-width: 768px) {
    .StickyTable_tableCategoriesList__3GJIc > li {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 12px; } }

.StickyTable_table__3UD2t thead th {
  font-size: 12px;
  background-color: #F1F1F1; }

.StickyTable_table__3UD2t td {
  height: 34px;
  padding: 0 24px 0 16px;
  font-size: 12px;
  line-height: 34px;
  background-color: #FAFAFA; }

.StickyTable_categoryCell__2et1O {
  white-space: nowrap; }

.DashboardDialogCoverageClassesContent_content__zWh7E {
  margin-top: 24px;
  overflow-y: scroll; }

.DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM {
  display: flex;
  margin-top: 0; }
  .DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM > div:first-child {
    width: 25%; }
  .DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM > div:last-child {
    width: 75%; }
  .DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM {
      flex-direction: column; }
      .DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM > div:first-child,
      .DashboardDialogCoverageClassesContent_chartsWrapper__FAZSM > div:last-child {
        width: 100%;
        margin-bottom: 16px; } }

.DashboardDialogCoverageClassesContent_dataTableWrapper__1zoVD {
  margin-top: 12px;
  margin-bottom: 16px; }

.DashboardDialogCoverageClassesContent_tableHeader__3u7k0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialogCoverageClassesContent_tableTitle__7vj7X {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500; }
  .DashboardDialogCoverageClassesContent_tableTitle__7vj7X b {
    font-weight: 500; }
  .DashboardDialogCoverageClassesContent_tableTitle__7vj7X > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px; }

.DashboardDialogCoverageClassesContent_tableWrapper__1WBsD {
  max-width: 100%;
  overflow-x: scroll; }

.DashboardDialogCoverageClassesContent_table__11ma4 th,
.DashboardDialogCoverageClassesContent_table__11ma4 td {
  font-size: 12px; }

.DashboardDialogCoverageClassesContent_table__11ma4 th {
  font-weight: 700;
  white-space: nowrap; }

.DashboardDialogCoverageClassesContent_loadingWrapper__1R8rT {
  margin-top: 32px;
  text-align: center; }

.DashboardDialogCoverageClassesContent_csvDownloadLink__13kax {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out; }
  .DashboardDialogCoverageClassesContent_csvDownloadLink__13kax svg {
    margin-right: 10px; }
  @media screen and (max-width: 480px) {
    .DashboardDialogCoverageClassesContent_csvDownloadLink__13kax {
      display: none; } }

.DashboardDialogCoverageNaturalOrAnthropicContent_content__2dMbX {
  margin-top: 24px;
  overflow-y: scroll; }

.DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH {
  display: flex;
  margin-top: 0; }
  .DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH > div:first-child {
    width: 25%; }
  .DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH > div:last-child {
    width: 75%; }
  .DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH {
      flex-direction: column; }
      .DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH > div:first-child,
      .DashboardDialogCoverageNaturalOrAnthropicContent_chartsWrapper__3aHpH > div:last-child {
        width: 100%;
        margin-bottom: 16px; } }

.DashboardDialogCoverageNaturalOrAnthropicContent_dataTableWrapper__2deoN {
  margin-top: 12px;
  margin-bottom: 16px; }

.DashboardDialogCoverageNaturalOrAnthropicContent_tableHeader__3zIhj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialogCoverageNaturalOrAnthropicContent_tableTitle__3OGed {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700; }
  .DashboardDialogCoverageNaturalOrAnthropicContent_tableTitle__3OGed > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px; }

.DashboardDialogCoverageNaturalOrAnthropicContent_tableWrapper__BCYJ- {
  max-width: 100%;
  overflow-x: scroll; }

.DashboardDialogCoverageNaturalOrAnthropicContent_table__2cR0U th,
.DashboardDialogCoverageNaturalOrAnthropicContent_table__2cR0U td {
  font-size: 12px; }

.DashboardDialogCoverageNaturalOrAnthropicContent_table__2cR0U th {
  font-weight: 700;
  white-space: nowrap; }

.DashboardDialogCoverageNaturalOrAnthropicContent_loadingWrapper__zy-Iw {
  margin-top: 32px;
  text-align: center; }

.DashboardDialogCoverageNaturalOrAnthropicContent_csvDownloadLink__1mYRU {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out; }
  .DashboardDialogCoverageNaturalOrAnthropicContent_csvDownloadLink__1mYRU svg {
    margin-right: 10px; }
  @media screen and (max-width: 480px) {
    .DashboardDialogCoverageNaturalOrAnthropicContent_csvDownloadLink__1mYRU {
      display: none; } }

.DashboardDialogQualityContent_content__qWcEr {
  margin-top: 12px;
  overflow-y: scroll; }

.DashboardDialogQualityContent_chartsWrapper__3RDTm {
  display: flex;
  margin-top: 0; }
  .DashboardDialogQualityContent_chartsWrapper__3RDTm > div:first-child {
    width: 25%; }
  .DashboardDialogQualityContent_chartsWrapper__3RDTm > div:last-child {
    width: 75%; }
  .DashboardDialogQualityContent_chartsWrapper__3RDTm h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500; }

.DashboardDialogQualityContent_dataTableWrapper__3ZJ5r {
  margin-top: 12px;
  margin-bottom: 16px; }

.DashboardDialogQualityContent_tableHeader__2OEaI {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialogQualityContent_tableTitle__37ImO {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700; }

.DashboardDialogQualityContent_tableWrapper__3TlNm {
  max-width: 100%;
  overflow-x: scroll; }

.DashboardDialogQualityContent_table__2tnoS th,
.DashboardDialogQualityContent_table__2tnoS td {
  font-size: 12px; }

.DashboardDialogQualityContent_table__2tnoS th {
  font-weight: 700;
  white-space: nowrap; }

.DashboardDialogQualityContent_loadingWrapper__1BAJT {
  margin-top: 32px;
  text-align: center; }

.DashboardDialogTransitionContent_content__27Owu {
  margin-top: 12px;
  overflow-y: scroll; }

.DashboardDialogTransitionContent_radioGroup__1URZE {
  flex-direction: row;
  justify-content: center; }
  .DashboardDialogTransitionContent_radioGroup__1URZE > label {
    margin-right: 32px; }
    .DashboardDialogTransitionContent_radioGroup__1URZE > label:last-child {
      margin-right: 0; }
    @media screen and (max-width: 768px) {
      .DashboardDialogTransitionContent_radioGroup__1URZE > label {
        margin-right: 16px; } }

.DashboardDialogTransitionContent_radioLabel__1BGSy {
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .DashboardDialogTransitionContent_radioLabel__1BGSy {
      font-size: 12px; } }

@media screen and (max-width: 768px) {
  .DashboardDialogTransitionContent_radioGroupInput__rHuYy {
    padding: 6px; } }

.DashboardDialogTransitionContent_tabListWrapper__2wffs {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 32px; }
  .DashboardDialogTransitionContent_tabListWrapper__2wffs li {
    padding: 10px 32px; }

.DashboardDialogTransitionContent_sankeyWrapper__3tssd {
  width: 100%;
  height: 478px; }

.DashboardDialogTransitionContent_sankeyYears__3x5O8 {
  margin-top: 0;
  text-align: center;
  font-size: 14px; }

.DashboardDialogTransitionContent_loadingWrapper__2iMC4 {
  margin-top: 32px;
  text-align: center; }

.DashboardDialogTransitionContent_categoryCell__3uuJE {
  white-space: nowrap; }

.DashboardDialogTransitionContent_tableContainer__3QTdm {
  overflow-y: scroll; }

.DashboardDialogTransitionContent_matrixTable__3kAHp td {
  height: 34px;
  padding: 0 24px 0 16px;
  line-height: 34px;
  background-color: #F1F1F1; }

@media screen and (max-width: 768px) {
  .DashboardDialogTransitionContent_matrixTable__3kAHp th, .DashboardDialogTransitionContent_matrixTable__3kAHp td {
    font-size: 12px; } }

.DashboardDialogTransitionContent_tableWrapper__vqAtI {
  position: relative;
  display: flex;
  padding-top: 40px;
  padding-left: 40px;
  background-color: #F1F1F1;
  overflow: scroll; }
  .DashboardDialogTransitionContent_tableWrapper__vqAtI::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 56px;
    height: 56px;
    border: 2px solid #CCC;
    border-width: 0 2px 0 0;
    -webkit-transform: rotate(-45deg) translate3d(-27px, -11px, 0);
            transform: rotate(-45deg) translate3d(-27px, -11px, 0); }
  @media screen and (max-width: 768px) {
    .DashboardDialogTransitionContent_tableWrapper__vqAtI {
      padding-top: 30px;
      padding-left: 30px; }
      .DashboardDialogTransitionContent_tableWrapper__vqAtI::after {
        width: 42px;
        height: 42px;
        -webkit-transform: rotate(-45deg) translate3d(-20px, -8px, 0);
                transform: rotate(-45deg) translate3d(-20px, -8px, 0); } }

.DashboardDialogTransitionContent_tableLeftTag__3ETu4 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40px;
  text-align: center;
  background-color: #DEDEDE; }
  .DashboardDialogTransitionContent_tableLeftTag__3ETu4 > span {
    position: absolute;
    top: 50%;
    display: block;
    -webkit-transform: rotate(-90deg) translateX(-25%);
            transform: rotate(-90deg) translateX(-25%); }
  @media screen and (max-width: 768px) {
    .DashboardDialogTransitionContent_tableLeftTag__3ETu4 {
      width: 30px; }
      .DashboardDialogTransitionContent_tableLeftTag__3ETu4 > span {
        font-size: 12px; } }

.DashboardDialogTransitionContent_tableTopTag__3qP67 {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  background-color: #DEDEDE; }
  @media screen and (max-width: 768px) {
    .DashboardDialogTransitionContent_tableTopTag__3qP67 {
      height: 30px;
      line-height: 30px; }
      .DashboardDialogTransitionContent_tableTopTag__3qP67 > span {
        font-size: 12px; } }

.DashboardDialogTransitionContent_tableCategoriesList__35zP8 {
  padding-top: 37px;
  padding-left: 0;
  margin: 0; }
  .DashboardDialogTransitionContent_tableCategoriesList__35zP8 > li {
    height: 35px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 34px;
    white-space: nowrap;
    font-size: 0.875rem;
    background-color: #fafafa;
    border-bottom: 1px solid #e0e0e0; }
  @media screen and (max-width: 768px) {
    .DashboardDialogTransitionContent_tableCategoriesList__35zP8 > li {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 12px; } }

.DashboardDialogTransitionContent_tableHeader__OKYbY {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialogTransitionContent_tableTitle__382Gq {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px; }
  .DashboardDialogTransitionContent_tableTitle__382Gq > b {
    font-weight: 500; }
  .DashboardDialogTransitionContent_tableTitle__382Gq > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px; }

.DashboardDialogTransitionContent_csvDownloadLink__rNDby {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out; }
  .DashboardDialogTransitionContent_csvDownloadLink__rNDby svg {
    margin-right: 10px; }
  @media screen and (max-width: 480px) {
    .DashboardDialogTransitionContent_csvDownloadLink__rNDby {
      display: none; } }

.SankeyChartControl_contentWrapper__pX1PA {
  position: relative; }

.SankeyChartControl_sankeyWrapper__2MaXr {
  width: 100%;
  height: 320px;
  overflow-y: scroll; }

.SankeyChartControl_sankeyYears__PM1JF {
  margin-top: 0;
  text-align: center;
  font-size: 14px; }

.SankeyChartControl_autocompleteFormControl__27IHs {
  margin-bottom: 24px; }

.SankeyChartControl_downloadButton__3BXCA {
  position: absolute;
  top: -4px;
  right: 6px;
  min-width: 46px;
  padding-left: 4px;
  padding-right: 4px;
  color: #5c5c5c; }
  .SankeyChartControl_downloadButton__3BXCA svg {
    font-size: 20px; }

.DashboardDialogPastureQualityContent_content__14-IA {
  margin-top: 24px;
  overflow-y: scroll; }

.DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 {
  display: flex;
  margin-top: 0; }
  .DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 > div:first-child {
    width: 25%; }
  .DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 > div:last-child {
    width: 75%; }
  .DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 {
      flex-direction: column; }
      .DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 > div:first-child,
      .DashboardDialogPastureQualityContent_chartsWrapper__qbM_5 > div:last-child {
        width: 100%;
        margin-bottom: 16px; } }

.DashboardDialogPastureQualityContent_dataTableWrapper__iSZlM {
  margin-top: 12px;
  margin-bottom: 16px; }

.DashboardDialogPastureQualityContent_tableHeader__1rcIf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialogPastureQualityContent_tableTitle__1m24E {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700; }
  .DashboardDialogPastureQualityContent_tableTitle__1m24E > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px; }

.DashboardDialogPastureQualityContent_tableWrapper__4CK__ {
  max-width: 100%;
  overflow-x: scroll; }

.DashboardDialogPastureQualityContent_table__33Pe- th,
.DashboardDialogPastureQualityContent_table__33Pe- td {
  font-size: 12px; }

.DashboardDialogPastureQualityContent_table__33Pe- th {
  font-weight: 700;
  white-space: nowrap; }

.DashboardDialogPastureQualityContent_loadingWrapper__3jssV {
  margin-top: 32px;
  text-align: center; }

.DashboardDialogPastureQualityContent_csvDownloadLink__3eBzV {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out; }
  .DashboardDialogPastureQualityContent_csvDownloadLink__3eBzV svg {
    margin-right: 10px; }
  @media screen and (max-width: 480px) {
    .DashboardDialogPastureQualityContent_csvDownloadLink__3eBzV {
      display: none; } }

.DashboardDialogGenericClassesContent_content__nqZ0r {
  margin-top: 24px;
  overflow-y: scroll; }

.DashboardDialogGenericClassesContent_chartsWrapper__2Jg-2 {
  display: flex;
  margin-top: 0; }
  .DashboardDialogGenericClassesContent_chartsWrapper__2Jg-2 h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .DashboardDialogGenericClassesContent_chartsWrapper__2Jg-2 {
      flex-direction: column; }
      .DashboardDialogGenericClassesContent_chartsWrapper__2Jg-2 > div:first-child,
      .DashboardDialogGenericClassesContent_chartsWrapper__2Jg-2 > div:last-child {
        width: 100%;
        margin-bottom: 16px; } }

.DashboardDialogGenericClassesContent_dataTableWrapper__1ZwLe {
  margin-top: 12px;
  margin-bottom: 16px; }

.DashboardDialogGenericClassesContent_tableHeader__2b1Wm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialogGenericClassesContent_tableTitle__3y-n7 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500; }
  .DashboardDialogGenericClassesContent_tableTitle__3y-n7 b {
    font-weight: 500; }
  .DashboardDialogGenericClassesContent_tableTitle__3y-n7 > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px; }

.DashboardDialogGenericClassesContent_tableWrapper__1fiix {
  max-width: 100%;
  overflow-x: scroll; }

.DashboardDialogGenericClassesContent_table__BLGOQ th,
.DashboardDialogGenericClassesContent_table__BLGOQ td {
  font-size: 12px; }

.DashboardDialogGenericClassesContent_table__BLGOQ th {
  font-weight: 700;
  white-space: nowrap; }

.DashboardDialogGenericClassesContent_loadingWrapper__3HHAe {
  margin-top: 32px;
  text-align: center; }

.DashboardDialogGenericClassesContent_csvDownloadLink__2_gUh {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out; }
  .DashboardDialogGenericClassesContent_csvDownloadLink__2_gUh svg {
    margin-right: 10px; }
  @media screen and (max-width: 480px) {
    .DashboardDialogGenericClassesContent_csvDownloadLink__2_gUh {
      display: none; } }

.DashboardDialog_wrapper__1S_fw {
  position: absolute;
  top: 16px;
  left: 282px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: calc(100% - 362px);
  height: calc(100% - 80px);
  padding: 16px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width 0.5s ease, height 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  -webkit-transform: translateX(64px);
          transform: translateX(64px); }
  @media screen and (max-width: 768px) {
    .DashboardDialog_wrapper__1S_fw {
      width: calc(100% - 20px);
      height: calc(100% - 92px);
      -webkit-transform: translateX(10px);
              transform: translateX(10px); } }

.DashboardDialog_wrapperWithHeader__3wLws {
  width: calc(100% - 469px);
  -webkit-transform: translateX(170px);
          transform: translateX(170px); }

.DashboardDialog_wrapperIsCollapsed__2qPMg {
  height: 48px; }

.DashboardDialog_header__2Da0s {
  position: relative; }

.DashboardDialog_title__1EPsa {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.DashboardDialog_toggleButton__2l4NO {
  position: absolute;
  top: -7px;
  right: 36px;
  padding: 3px; }
  .DashboardDialog_toggleButton__2l4NO svg {
    font-size: 24px;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease; }

.DashboardDialog_toggleButtonIsCollapsed__3ZUKx svg {
  font-size: 24px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.DashboardDialog_closeButton__1gW8d {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px; }
  .DashboardDialog_closeButton__1gW8d svg {
    font-size: 18px; }

.DashboardDialog_content__17dqb {
  margin-top: 24px;
  overflow-y: scroll; }

.DashboardDialog_chartsWrapper__1lgSx {
  display: flex;
  margin-top: 16px; }
  .DashboardDialog_chartsWrapper__1lgSx > div:first-child {
    width: 25%; }
  .DashboardDialog_chartsWrapper__1lgSx > div:last-child {
    width: 75%; }
  .DashboardDialog_chartsWrapper__1lgSx h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500; }

.DashboardDialog_dataTableWrapper__1Zegm {
  margin-top: 12px;
  margin-bottom: 16px; }

.DashboardDialog_tableHeader__3btOs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.DashboardDialog_tableTitle__3oS-0 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500; }
  .DashboardDialog_tableTitle__3oS-0 b {
    font-weight: 500; }

.DashboardDialog_tableWrapper__2mii3 {
  max-width: 100%;
  overflow-x: scroll; }

.DashboardDialog_table__c8_ec th,
.DashboardDialog_table__c8_ec td {
  font-size: 12px; }

.DashboardDialog_table__c8_ec th {
  font-weight: 700;
  white-space: nowrap; }

.DashboardDialog_loadingWrapper__170cT {
  margin-top: 32px;
  text-align: center; }

.DashboardDialog_smallText__2k6Zj {
  margin-bottom: 0;
  font-size: 12px; }

.DashboardDialog_alertInfo__3kqx9 {
  padding: 12px 24px;
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: .25rem; }

.MapCanvas_mapWrapper__2LD9g .leaflet-control-zoom,
.MapCanvas_mapWrapper__2LD9g #box-zoom-button,
.MapCanvas_mapWrapper__2LD9g .leaflet-left,
.MapCanvas_mapWrapper__2LD9g .leaflet-right {
  transition: all .5s ease; }

.MapCanvas_mapWrapper__2LD9g .leaflet-left {
  bottom: 18px;
  -webkit-transform: translate3d(16px, -239px, 0);
          transform: translate3d(16px, -239px, 0); }
  @media screen and (max-width: 768px) {
    .MapCanvas_mapWrapper__2LD9g .leaflet-left {
      -webkit-transform: translate3d(10px, -239px, 0);
              transform: translate3d(10px, -239px, 0); } }

.MapCanvas_mapWrapper__2LD9g .leaflet-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.MapCanvas_mapWrapper__2LD9g .leaflet-control-zoom {
  margin: 0;
  top: -42px;
  left: 0;
  border-radius: 6px;
  overflow: hidden; }

.MapCanvas_mapWrapper__2LD9g .leaflet-control-scale {
  margin: 0;
  left: 136px;
  bottom: -255px; }
  @media screen and (max-width: 1024px) {
    .MapCanvas_mapWrapper__2LD9g .leaflet-control-scale {
      left: 44px;
      bottom: -190px; } }

@media screen and (max-width: 1023px) {
  .MapCanvas_mapWrapper__2LD9g .leaflet-touch .leaflet-bar {
    border: none !important; } }

@media screen and (max-width: 768px) {
  .MapCanvas_mapWrapper__2LD9g .leaflet-bottom .leaflet-control-scale {
    display: none; } }

.MapCanvas_mapWrapperWithHeader__2kFj8 .leaflet-left {
  -webkit-transform: translate3d(410px, -239px, 0);
          transform: translate3d(410px, -239px, 0); }
  @media screen and (max-width: 960px) {
    .MapCanvas_mapWrapperWithHeader__2kFj8 .leaflet-left {
      -webkit-transform: translate3d(390px, -239px, 0);
              transform: translate3d(390px, -239px, 0); } }

.MapCanvas_mapWrapperWithHeader__2kFj8 .MapCanvas_mapSearchButton__1xXmk {
  -webkit-transform: translate3d(410px, -215px, 0);
          transform: translate3d(410px, -215px, 0); }
  @media screen and (max-width: 960px) {
    .MapCanvas_mapWrapperWithHeader__2kFj8 .MapCanvas_mapSearchButton__1xXmk {
      -webkit-transform: translate3d(390px, -215px, 0);
              transform: translate3d(390px, -215px, 0); } }

.MapCanvas_mapWrapperOnPointSearchMode__fHoB- {
  cursor: pointer; }

.MapCanvas_mapActionButton__oC75T {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2000;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  outline: none;
  cursor: pointer;
  transition: all .5s ease;
  border-radius: 6px; }
  .MapCanvas_mapActionButton__oC75T:hover {
    background-color: #F1F1F1; }
  .MapCanvas_mapActionButton__oC75T svg {
    font-size: 22px;
    fill: #555; }

.MapCanvas_mapSearchButton__1xXmk {
  -webkit-transform: translate3d(16px, -215px, 0);
          transform: translate3d(16px, -215px, 0); }
  @media screen and (max-width: 768px) {
    .MapCanvas_mapSearchButton__1xXmk {
      -webkit-transform: translate3d(10px, -215px, 0);
              transform: translate3d(10px, -215px, 0);
      z-index: 2000; } }

.MapCanvas_tutorialButton__2tmhx {
  bottom: 367px; }
  @media screen and (max-width: 768px) {
    .MapCanvas_tutorialButton__2tmhx {
      right: 10px;
      z-index: 2000; } }

.MapCanvas_mapGeographicalReliefButton__3gpmJ {
  bottom: 24px; }

.MapCanvas_loadingWrapper__4TvGH {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%); }

.MapCanvas_opacityBox__15fVb {
  position: absolute;
  bottom: 100px;
  right: 100px;
  z-index: 2000;
  width: 50px;
  height: 120px;
  padding: 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: all .5s ease; }

.CoordinatesInfo_wrapper__3JFHO {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2000;
  transition: all .5s ease;
  -webkit-transform: translate3d(442px, -66px, 0);
          transform: translate3d(442px, -66px, 0); }
  @media screen and (max-width: 1024px) {
    .CoordinatesInfo_wrapper__3JFHO {
      display: none; } }

.CoordinatesInfo_wrapperWithoutHeader__IYQtW {
  -webkit-transform: translate3d(48px, -66px, 0);
          transform: translate3d(48px, -66px, 0); }

.CoordinatesInfo_controlWrapper__RBEi6 {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.CoordinatesInfo_mouseCoordinatesInfo__uZALW {
  width: 94px;
  text-align: center;
  font-size: 12px;
  color: #333; }

.leaflet-sbs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.leaflet-sbs-range {
  position: absolute;
  top: 15%;
  left: -9px;
  width: 100%;
  z-index: 999;
}
.leaflet-sbs-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  background-color: #fff;
  pointer-events: none;
  z-index: 999;
}

.leaflet-sbs-divider::before,
.leaflet-sbs-divider::after {
  position: absolute;
  top: 15%;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
}

.leaflet-sbs-divider::before {
  content: attr(data-from-year);
  left: 0;
  padding-right: 25px;
  padding-left: 12px;
  -webkit-transform: translate3d(-100%, -50%, 0);
          transform: translate3d(-100%, -50%, 0);
}

.leaflet-sbs-divider::after {
  content: attr(data-to-year);
  left: 0;
  padding-left: 25px;
  padding-right: 12px;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}

.leaflet-sbs-range {
  -webkit-appearance: none;
  display: inline-block!important;
  vertical-align: middle;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.25);
  min-width: 100px;
  cursor: pointer;
  pointer-events: none;
  z-index: 999;
}

.leaflet-sbs-divider-control {
  position: absolute;
  left: 50%;
  top: 15%;
  z-index: 1000;
  width: 40px;
  height: 40px;
  background-color: white;
  background-image: url('/range-icon.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.leaflet-sbs-range::-ms-fill-upper {
  background: transparent;
}
.leaflet-sbs-range::-ms-fill-lower {
  background: rgba(255, 255, 255, 0.25);
}
/* Browser thingies */

.leaflet-sbs-range::-moz-range-track {
  opacity: 0;
}
.leaflet-sbs-range::-ms-track {
  opacity: 0;
}
.leaflet-sbs-range::-ms-tooltip {
  display: none;
}
/* For whatever reason, these need to be defined
* on their own so dont group them */

.leaflet-sbs-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
}
.leaflet-sbs-range::-ms-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
}
.leaflet-sbs-range::-moz-range-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: none;
}
.leaflet-sbs-range:disabled::-moz-range-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled::-ms-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled::-webkit-slider-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled {
  cursor: default;
}
.leaflet-sbs-range:focus {
  outline: none!important;
}
.leaflet-sbs-range::-moz-focus-outer {
  border: 0;
}

@media screen and (max-width: 768px) {
  .leaflet-sbs-divider::before,
  .leaflet-sbs-divider::after {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 500;
  }

  .leaflet-sbs-divider::before {
    padding-right: 28px;
  }

  .leaflet-sbs-divider::after {
    padding-left: 28px;
  }
}

.MapboxCanvas_mapWrapper__PT5jt h1 {
  text-align: center; }

.MapboxCanvas_mapWrapper__PT5jt .leaflet-control-zoom,
.MapboxCanvas_mapWrapper__PT5jt #box-zoom-button,
.MapboxCanvas_mapWrapper__PT5jt .leaflet-left,
.MapboxCanvas_mapWrapper__PT5jt .leaflet-right {
  transition: all .5s ease; }

.MapboxCanvas_mapWrapper__PT5jt .leaflet-left {
  bottom: 18px;
  -webkit-transform: translate3d(16px, -239px, 0);
          transform: translate3d(16px, -239px, 0); }
  @media screen and (max-width: 768px) {
    .MapboxCanvas_mapWrapper__PT5jt .leaflet-left {
      -webkit-transform: translate3d(10px, -239px, 0);
              transform: translate3d(10px, -239px, 0); } }

.MapboxCanvas_mapWrapper__PT5jt .leaflet-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.MapboxCanvas_mapWrapper__PT5jt .leaflet-control-zoom {
  margin: 0;
  top: -42px;
  left: 0;
  border-radius: 6px;
  overflow: hidden; }

.MapboxCanvas_mapWrapper__PT5jt .leaflet-control-scale {
  margin: 0;
  left: 136px;
  bottom: -255px; }
  @media screen and (max-width: 1024px) {
    .MapboxCanvas_mapWrapper__PT5jt .leaflet-control-scale {
      left: 44px;
      bottom: -190px; } }

@media screen and (max-width: 1023px) {
  .MapboxCanvas_mapWrapper__PT5jt .leaflet-touch .leaflet-bar {
    border: none !important; } }

@media screen and (max-width: 768px) {
  .MapboxCanvas_mapWrapper__PT5jt .leaflet-bottom .leaflet-control-scale {
    display: none; } }

.MapboxCanvas_mapWrapperWithHeader__2vGvz .leaflet-left {
  -webkit-transform: translate3d(410px, -239px, 0);
          transform: translate3d(410px, -239px, 0); }
  @media screen and (max-width: 960px) {
    .MapboxCanvas_mapWrapperWithHeader__2vGvz .leaflet-left {
      -webkit-transform: translate3d(390px, -239px, 0);
              transform: translate3d(390px, -239px, 0); } }

.MapboxCanvas_mapWrapperWithHeader__2vGvz .MapboxCanvas_mapSearchButton__27hVp {
  -webkit-transform: translate3d(410px, -215px, 0);
          transform: translate3d(410px, -215px, 0); }
  @media screen and (max-width: 960px) {
    .MapboxCanvas_mapWrapperWithHeader__2vGvz .MapboxCanvas_mapSearchButton__27hVp {
      -webkit-transform: translate3d(390px, -215px, 0);
              transform: translate3d(390px, -215px, 0); } }

.MapboxCanvas_mapWrapperOnPointSearchMode__2rnLU {
  cursor: pointer; }

.MapboxCanvas_mapActionButton__3ejUq {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2000;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  outline: none;
  cursor: pointer;
  transition: all .5s ease;
  border-radius: 6px; }
  .MapboxCanvas_mapActionButton__3ejUq:hover {
    background-color: #F1F1F1; }
  .MapboxCanvas_mapActionButton__3ejUq svg {
    font-size: 22px;
    fill: #555; }

.MapboxCanvas_mapSearchButton__27hVp {
  -webkit-transform: translate3d(16px, -215px, 0);
          transform: translate3d(16px, -215px, 0); }
  @media screen and (max-width: 768px) {
    .MapboxCanvas_mapSearchButton__27hVp {
      -webkit-transform: translate3d(10px, -215px, 0);
              transform: translate3d(10px, -215px, 0);
      z-index: 2000; } }

.MapboxCanvas_tutorialButton__10iX- {
  bottom: 367px; }
  @media screen and (max-width: 768px) {
    .MapboxCanvas_tutorialButton__10iX- {
      right: 10px;
      z-index: 2000; } }

.MapboxCanvas_mapGeographicalReliefButton__3BCB_ {
  bottom: 24px; }

.MapboxCanvas_loadingWrapper__3LB3r {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%); }

.MapboxCanvas_opacityBox__2AFt3 {
  position: absolute;
  bottom: 100px;
  right: 100px;
  z-index: 2000;
  width: 50px;
  height: 120px;
  padding: 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: all .5s ease; }

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin: 0; }

.leaflet-draw {
  display: none; }

.MapboxControl_wrapper__176iZ {
  position: absolute;
  left: 16px;
  bottom: 414px;
  z-index: 2000;
  transition: all .5s ease; }
  @media screen and (max-width: 768px) {
    .MapboxControl_wrapper__176iZ {
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px); } }

.MapboxControl_wrapperHeader__37fUj {
  -webkit-transform: translate(395px, 0px);
          transform: translate(395px, 0px); }

.MapboxControl_controlWrapper__2pvMD {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }

.MapboxControl_searchButton__7T9k0 {
  width: 32px;
  height: 32px;
  padding: 0;
  font-weight: 700;
  color: #555;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  border-radius: 6px; }
  .MapboxControl_searchButton__7T9k0:hover {
    background-color: #F1F1F1; }
  .MapboxControl_searchButton__7T9k0 svg {
    font-size: 22px;
    fill: #555; }

.MapboxControl_title__ZPr38 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px; }

.MapboxControl_formWrapper__3Lf2q {
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 320px;
  display: flex;
  align-items: center;
  padding: 16px 10px 12px 16px;
  background-color: #F7F7F7;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24); }
  .MapboxControl_formWrapper__3Lf2q h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085; }
  .MapboxControl_formWrapper__3Lf2q label {
    background-color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .MapboxControl_formWrapper__3Lf2q {
      width: 280px;
      padding: 16px 10px 12px 10px; } }

.MapboxControl_textInfo__11CTa {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.MapboxControl_formControl__29F67 {
  width: 120px;
  margin-right: 12px; }
  .MapboxControl_formControl__29F67 input {
    text-align: right; }

.MapboxControl_searchIconButton__1Q29S {
  padding: 8px;
  margin-left: auto; }
  .MapboxControl_searchIconButton__1Q29S svg {
    font-size: 20px;
    color: #777; }

.MapControls_wrapper__19yyu {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  transition: all .5s ease;
  -webkit-transform: translate3d(410px, -109px, 0);
          transform: translate3d(410px, -109px, 0); }
  @media screen and (max-width: 960px) {
    .MapControls_wrapper__19yyu {
      -webkit-transform: translate3d(390px, -109px, 0);
              transform: translate3d(390px, -109px, 0); } }

.MapControls_baseMapsItemWrapper__-sfxq {
  display: flex;
  justify-content: space-between; }

.MapControls_baseMapsContentWrapper__1DHH8 {
  max-height: 271px;
  overflow: scroll; }

.MapControls_wrapperWithoutHeader__1szev {
  -webkit-transform: translate3d(16px, -109px, 0);
          transform: translate3d(16px, -109px, 0); }
  @media screen and (max-width: 768px) {
    .MapControls_wrapperWithoutHeader__1szev {
      -webkit-transform: translate3d(10px, -109px, 0);
              transform: translate3d(10px, -109px, 0); } }

@media screen and (max-width: 768px) {
  .MapControls_wrapperWithBeforeAndAfterMosaic__3DQgB {
    bottom: 24px; } }

.MapControls_actionBox__2hCcq {
  position: relative; }
  .MapControls_actionBox__2hCcq:first-child button {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
  .MapControls_actionBox__2hCcq:last-child button {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }

.MapControls_actionBoxContent__3nUz4 {
  position: absolute;
  left: 42px;
  bottom: 0;
  width: 400px;
  padding: 10px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24); }
  .MapControls_actionBoxContent__3nUz4 h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .07em;
    color: #353f4d; }
  @media screen and (max-width: 420px) {
    .MapControls_actionBoxContent__3nUz4 {
      width: 260px; } }
  @media screen and (max-width: 320px) {
    .MapControls_actionBoxContent__3nUz4 {
      width: 240px; } }

.MapControls_actionBoxContentOpacity__12buy {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 160px;
  padding: 16px 0 10px; }

.MapControls_actionButton__3KZ41 {
  width: 32px;
  height: 32px;
  padding: 2px 0 0 0;
  background-color: white;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  outline: none;
  cursor: pointer; }
  .MapControls_actionButton__3KZ41:hover {
    background-color: #F7F7F7;
    border-color: #F7F7F7; }
  .MapControls_actionButton__3KZ41 svg {
    font-size: 22px;
    fill: #555; }

.MapControls_actionButtonDisabled__1aTbP {
  pointer-events: none; }
  .MapControls_actionButtonDisabled__1aTbP svg {
    fill: #CACACA; }

.MapControls_switchControl__FBSys,
.MapControls_radioGroupControl__1O4ce {
  width: 100%;
  margin-left: 0; }

.MapControls_switchControl__FBSys {
  margin-bottom: 4px; }

.MapControls_radioGroupControl__1O4ce {
  margin-bottom: 6px; }
  .MapControls_radioGroupControl__1O4ce:last-child {
    margin-bottom: 0; }

.MapControls_switchLabel__1yB2U,
.MapControls_radioGroupLabel__3zcZs {
  margin-left: 6px;
  font-size: 12px;
  color: #555; }

.MapControls_radioGroupInput__20zwy {
  padding: 0; }

.MapControls_actionBoxContentScrollWrapper__2c2IC {
  max-height: 380px;
  overflow-y: scroll; }
  @media screen and (max-height: 720px) {
    .MapControls_actionBoxContentScrollWrapper__2c2IC {
      max-height: 236px;
      overflow-y: scroll; } }

.MapControls_actionBoxItemWrapper__-XhDL {
  display: flex;
  flex-direction: column; }

.MapControls_actionBoxItemHeader__3ngvg {
  display: flex; }

.MapControls_actionBoxItemControl__2rC_9 {
  flex: 1 1; }

.MapControls_actionBoxControlButton__3UIMD {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 4px; }
  .MapControls_actionBoxControlButton__3UIMD svg {
    font-size: 16px; }
  .MapControls_actionBoxControlButton__3UIMD.MapControls_actionBoxControlButtonToggle__3dKaT svg {
    font-size: 20px; }

.MapControls_actionBoxItemLegend__1K30g {
  padding: 4px 8px;
  margin-left: 46px;
  margin-bottom: 12px;
  background-color: #F1F1F1; }
  .MapControls_actionBoxItemLegend__1K30g p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.4;
    color: #555; }
  .MapControls_actionBoxItemLegend__1K30g ul {
    padding: 0;
    margin: 0; }
    .MapControls_actionBoxItemLegend__1K30g ul li {
      margin-bottom: 4px;
      font-size: 12px; }
      .MapControls_actionBoxItemLegend__1K30g ul li:last-child {
        margin-bottom: 0; }
      .MapControls_actionBoxItemLegend__1K30g ul li > span:first-child {
        color: #777; }
      .MapControls_actionBoxItemLegend__1K30g ul li > span:last-child {
        color: #444; }

.MapPointDialog_wrapper__16jMJ {
  padding: 16px;
  background-color: white;
  width: 420px;
  position: absolute;
  top: 2px;
  z-index: 4000;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  transition: width 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: width 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  left: 16px;
  overflow-y: auto;
  border-radius: 6px;
  height: auto; }
  @media screen and (max-width: 620px) {
    .MapPointDialog_wrapper__16jMJ {
      width: calc(100% - 30px);
      top: 66px;
      -webkit-transform: translate(0px, 80px);
              transform: translate(0px, 80px); } }

.MapPointDialog_wrapperWithHeader__jp-PI {
  height: calc(100% - 200px);
  left: 0px;
  -webkit-transform: translate(410px, 60px);
          transform: translate(410px, 60px); }

.MapPointDialog_header__1IUMY {
  position: relative; }

.MapPointDialog_title__3Ibbd {
  margin: 0;
  font-size: 16px;
  font-weight: 600; }

.MapPointDialog_titleCar__13Owt {
  margin: 12px 0px 0px;
  font-size: 16px;
  font-weight: 600; }

.MapPointDialog_closeButton__BGxPO {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px; }
  .MapPointDialog_closeButton__BGxPO svg {
    font-size: 18px; }

.MapPointDialog_dataList__1oDcE {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 0; }

.MapPointDialog_dataListCar__3xwQi {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 12px;
  margin-bottom: 0; }

.MapPointDialog_dataListItem__1BTS2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 50%; }
  .MapPointDialog_dataListItem__1BTS2:last-child {
    margin-bottom: 0; }

.MapPointDialog_dataListItemFull__5Da3d {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%; }
  .MapPointDialog_dataListItemFull__5Da3d span {
    margin-left: 5px;
    margin-bottom: 4px; }
  .MapPointDialog_dataListItemFull__5Da3d:last-child {
    margin-bottom: 0; }

.MapPointDialog_dataListCarItemFull__2wCqf {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
  width: 100%; }
  .MapPointDialog_dataListCarItemFull__2wCqf span {
    margin-left: 5px;
    margin-bottom: 4px; }
  .MapPointDialog_dataListCarItemFull__2wCqf:last-child {
    margin-bottom: 0; }

.MapPointDialog_dataListItemLabel__3q6h0 {
  margin-bottom: 4px;
  font-size: 12px;
  color: #777; }

.MapPointDialog_dataListItemValue__3gQwJ {
  font-size: 12px;
  color: #444; }

.MapPointDialog_chartWrapper__1LITi {
  margin-top: 16px;
  margin-bottom: 16px; }

.YearTimeline_wrapper__18l33 {
  position: absolute;
  bottom: 24px;
  left: 0;
  z-index: 4000;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  padding-left: 8px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0px 2px 7px rgba(125, 125, 125, 0.24);
  transition: all .5s ease;
  -webkit-transform: translateX(16px);
          transform: translateX(16px);
  background-color: white;
  border-radius: 6px; }
  @media screen and (max-width: 768px) {
    .YearTimeline_wrapper__18l33 {
      width: calc(100% - 20px);
      left: 10px;
      z-index: 3000;
      -webkit-transform: translateX(0);
              transform: translateX(0); } }

.YearTimeline_wrapperWithHeader__1R49Z {
  width: calc(100% - 426px);
  -webkit-transform: translateX(410px);
          transform: translateX(410px); }
  @media screen and (max-width: 960px) {
    .YearTimeline_wrapperWithHeader__1R49Z {
      width: calc(100% - 406px);
      -webkit-transform: translateX(390px);
              transform: translateX(390px); } }
  @media screen and (max-width: 768px) {
    .YearTimeline_wrapperWithHeader__1R49Z {
      display: none; } }

.YearTimeline_sliderWrapper__23thR {
  position: relative;
  z-index: 2000;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 16px; }

.YearTimeline_timelineMark__3ofMN {
  width: 4px;
  height: 4px;
  margin-top: -1px;
  border-radius: 4px; }
  @media screen and (max-width: 768px) {
    .YearTimeline_timelineMark__3ofMN {
      width: 2px;
      height: 2px;
      margin-top: 0;
      border-radius: 2px; } }

.YearTimeline_headerInfo__2PXu_ {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: -31px;
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 8px;
  background-color: white;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24); }
  @media screen and (max-width: 768px) {
    .YearTimeline_headerInfo__2PXu_ {
      left: 0;
      -webkit-transform: translateX(0%);
              transform: translateX(0%); } }

.YearTimeline_headerInfoLabel__37jPy {
  font-size: 14px;
  color: #777; }

.YearTimeline_headerInfoValue__35cuN {
  margin-left: 6px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500; }

.YearTimeline_headerInfoToggleButton__1FLTX {
  padding: 4px; }
  .YearTimeline_headerInfoToggleButton__1FLTX svg {
    font-size: 20px; }

.YearTimeline_timelineInfo__249ez {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .YearTimeline_timelineInfo__249ez span:last-child {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap; }

.YearTimelineMultiple_wrapper__2F2cS {
  position: absolute;
  bottom: 24px;
  left: 0;
  z-index: 4000;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  padding-left: 8px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0px 2px 7px rgba(125, 125, 125, 0.24);
  transition: all .5s ease;
  -webkit-transform: translateX(16px);
          transform: translateX(16px);
  background-color: white;
  border-radius: 6px; }
  @media screen and (max-width: 768px) {
    .YearTimelineMultiple_wrapper__2F2cS {
      width: calc(100% - 20px);
      left: 10px;
      z-index: 3000;
      -webkit-transform: translateX(0);
              transform: translateX(0); } }

.YearTimelineMultiple_wrapperWithHeader__18c2G {
  width: calc(100% - 426px);
  -webkit-transform: translateX(410px);
          transform: translateX(410px); }
  @media screen and (max-width: 960px) {
    .YearTimelineMultiple_wrapperWithHeader__18c2G {
      width: calc(100% - 406px);
      -webkit-transform: translateX(390px);
              transform: translateX(390px); } }
  @media screen and (max-width: 768px) {
    .YearTimelineMultiple_wrapperWithHeader__18c2G {
      display: none; } }

.YearTimelineMultiple_sliderWrapper__APNNB {
  position: relative;
  z-index: 2000;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 16px; }

.YearTimelineMultiple_timelineMark__24jcs {
  width: 4px;
  height: 4px;
  margin-top: -1px;
  border-radius: 4px; }
  @media screen and (max-width: 768px) {
    .YearTimelineMultiple_timelineMark__24jcs {
      width: 2px;
      height: 2px;
      margin-top: 0;
      border-radius: 2px; } }

.YearTimelineMultiple_headerInfo__2xCBc {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: -31px;
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 8px;
  background-color: white;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24); }
  @media screen and (max-width: 768px) {
    .YearTimelineMultiple_headerInfo__2xCBc {
      left: 0;
      -webkit-transform: translateX(0%);
              transform: translateX(0%); } }

.YearTimelineMultiple_headerInfoLabel__3c2GL {
  font-size: 14px;
  color: #777; }

.YearTimelineMultiple_headerInfoValue__3gC-s {
  margin-left: 6px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500; }

.YearTimelineMultiple_headerInfoToggleButton__C0PE7 {
  padding: 4px; }
  .YearTimelineMultiple_headerInfoToggleButton__C0PE7 svg {
    font-size: 20px; }

.YearTimelineMultiple_timelineInfo__3xF_j {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .YearTimelineMultiple_timelineInfo__3xF_j span:last-child {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap; }

.YearTimelineMultiple_valueText__2BD0l {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  width: 222px; }

.ShareBox_title__WiPG3 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #555; }

.ShareBox_boxWrapper__3TyNt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #F7F7F7;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }
  .ShareBox_boxWrapper__3TyNt h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085; }
  .ShareBox_boxWrapper__3TyNt label {
    background-color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .ShareBox_boxWrapper__3TyNt {
      left: 42px; } }

.ShareBox_textInfo__3FgL6 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.ShareBox_formControl__2hn95 {
  width: 120px;
  margin-right: 12px; }
  .ShareBox_formControl__2hn95 input {
    text-align: right; }

.ShareBox_searchIconButton__29YgR {
  padding: 8px;
  margin-left: auto; }
  .ShareBox_searchIconButton__29YgR svg {
    font-size: 20px;
    color: #777; }

.ShareBox_socialList__28h88 {
  padding: 0;
  margin: 0;
  margin-top: 0;
  margin-bottom: 12px;
  list-style: none; }

.ShareBox_socialItem__6JBJR {
  display: inline-block;
  margin-right: 16px; }
  .ShareBox_socialItem__6JBJR:last-child {
    margin-right: 0; }
  .ShareBox_socialItem__6JBJR > div {
    cursor: pointer;
    outline: none; }

.ShareControl_wrapper__vZQHo {
  position: absolute;
  left: 16px;
  bottom: 372px;
  z-index: 2000;
  transition: all .5s ease; }
  @media screen and (max-width: 768px) {
    .ShareControl_wrapper__vZQHo {
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px); } }

.ShareControl_wrapperHeader__1_znC {
  -webkit-transform: translate(395px, 0px);
          transform: translate(395px, 0px); }

.ShareControl_controlWrapper__3JuA7 {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px; }

.ShareControl_searchButton__P3uKz {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  border-radius: 6px; }
  .ShareControl_searchButton__P3uKz:hover {
    background-color: #F1F1F1; }
  .ShareControl_searchButton__P3uKz svg {
    font-size: 22px;
    fill: #555; }

.ShareControl_title__1m_gh {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px; }

.ShareControl_formWrapper__2V_gZ {
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 320px;
  display: flex;
  align-items: center;
  padding: 16px 10px 12px 16px;
  background-color: #F7F7F7;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24); }
  .ShareControl_formWrapper__2V_gZ h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085; }
  .ShareControl_formWrapper__2V_gZ label {
    background-color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .ShareControl_formWrapper__2V_gZ {
      width: 280px;
      padding: 16px 10px 12px 10px; } }

.ShareControl_textInfo__3ojx6 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4; }

.ShareControl_formControl__2RWmT {
  width: 120px;
  margin-right: 12px; }
  .ShareControl_formControl__2RWmT input {
    text-align: right; }

.ShareControl_searchIconButton__3zU-V {
  padding: 8px;
  margin-left: auto; }
  .ShareControl_searchIconButton__3zU-V svg {
    font-size: 20px;
    color: #777; }

.MinimalBarData_wrapper__2bIMu {
  width: 100%; }

.MinimalBarData_bar__1I6VI {
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 5px; }

.MinimalBarData_backgroundBar__2ls0D,
.MinimalBarData_mainBar__278wf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background-color: #DEDEDE; }

.MinimalBarData_mainBar__278wf {
  width: 0%;
  background-color: #e74c3c; }

.MinimalBarData_info__1OZwo {
  display: flex;
  justify-content: space-between; }

.MinimalBarData_infoLabel__1uKMr {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400; }

.MinimalBarData_infoValue__2IPhf {
  font-size: 11px; }
  .MinimalBarData_infoValue__2IPhf b {
    display: inline-flex;
    font-size: 12px;
    margin-right: 4px;
    font-weight: 500; }
  .MinimalBarData_infoValue__2IPhf small {
    font-size: 10px; }

.StatisticsBox_wrapper__2j0xd {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-height: calc(100% - 84px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width 0.5s ease, height 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @media screen and (max-width: 620px) {
    .StatisticsBox_wrapper__2j0xd {
      width: calc(100% - 30px);
      -webkit-transform: translate(16px, 30px);
              transform: translate(16px, 30px);
      max-height: calc(100% - 156px); } }

.StatisticsBox_wrapperIsHidden__3gnsT {
  display: none;
  visibility: hidden; }

.StatisticsBox_header__3uZ3K {
  position: relative;
  padding: 16px; }

.StatisticsBox_title__1ZtiN {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.StatisticsBox_closeButton__9F5hi {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 3px; }
  .StatisticsBox_closeButton__9F5hi:hover svg {
    fill: #8E8E8E; }
  .StatisticsBox_closeButton__9F5hi svg {
    font-size: 26px;
    fill: #A2A2A2;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: fill 200ms ease-in-out, -webkit-transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease, -webkit-transform .5s ease; }

.StatisticsBox_closeButtonCollapse__3AAPi svg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.StatisticsBox_collapseWrapper__2hnvx {
  overflow: auto; }

.StatisticsBox_content__36hV4 {
  position: relative;
  overflow-y: auto;
  padding: 4px 16px 16px; }

.StatisticsBox_section__OmOdZ {
  margin-bottom: 32px; }
  .StatisticsBox_section__OmOdZ:nth-child(2) {
    margin-bottom: 24px; }
  .StatisticsBox_section__OmOdZ:last-child {
    margin-bottom: 0; }

.StatisticsBox_sectionSmall__24KnK {
  margin-bottom: 24px; }

.StatisticsBox_secondaryTitle__1wt-B {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  color: #555; }
  .StatisticsBox_secondaryTitle__1wt-B > small {
    display: block;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #616161; }
  .StatisticsBox_secondaryTitle__1wt-B svg {
    position: relative;
    top: 3px; }

.StatisticsBox_secondaryTitleExtraInfo__396bn {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: #717171; }

.StatisticsBox_infoList__2mMbK {
  display: flex;
  padding-left: 0;
  padding-bottom: 8px;
  margin-top: 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #DEDEDE; }

.StatisticsBox_infoListItem__x8jsq {
  display: flex;
  flex-direction: column;
  margin-right: 42px; }

.StatisticsBox_infoListItemValue__17RPg {
  margin-bottom: 4px;
  font-size: 14px; }
  .StatisticsBox_infoListItemValue__17RPg b {
    font-weight: 500; }
  .StatisticsBox_infoListItemValue__17RPg small {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400; }

.StatisticsBox_infoListItemLabel__2pvhM {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400; }

.StatisticsBox_progressWrapper__1kf5Y {
  margin-bottom: 16px; }

.StatisticsBox_actionWrapper__1quOD {
  display: flex;
  justify-content: center;
  margin-top: -16px; }

.StatisticsBox_actionButton__1zlxe svg {
  font-size: 20px;
  margin-right: 8px; }

.StatisticsBox_infoBox__2d37C {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #d6d8db;
  border-radius: 4px;
  color: #383d41;
  background-color: #e2e3e5; }
  .StatisticsBox_infoBox__2d37C p {
    margin: 0;
    font-size: 12px;
    line-height: 1.4; }

.StatisticsBox_fireModeContentBox__1fAMQ,
.StatisticsBox_fireModeContentBoxExtra__1ZOkE {
  padding: 16px;
  margin-bottom: 16px;
  background: #F5F5F5;
  border-radius: 4px; }

.StatisticsBox_fireModeContentBoxExtra__1ZOkE {
  color: #856404;
  background-color: #fff3cd; }
  .StatisticsBox_fireModeContentBoxExtra__1ZOkE .StatisticsBox_fireModeContentBoxTextWrapper__2_ykY p {
    margin-top: 0;
    margin-bottom: 0; }

.StatisticsBox_fireModeContentBoxTitle__3OkKp {
  margin-top: 0;
  font-size: 14px;
  font-weight: 500; }

.StatisticsBox_fireModeContentBoxTextWrapper__2_ykY p {
  font-size: 13px;
  line-height: 1.4; }
  .StatisticsBox_fireModeContentBoxTextWrapper__2_ykY p:last-child {
    margin-bottom: 0; }

.StatisticsBox_fireModeContentBoxTextWrapper__2_ykY > ul {
  padding-left: 16px;
  margin-top: 8px;
  margin-bottom: 0; }
  .StatisticsBox_fireModeContentBoxTextWrapper__2_ykY > ul > li {
    display: block;
    margin-bottom: 6px;
    font-size: 12px; }

.StatisticsBox_bigNumber__2wBhp {
  margin-top: 8px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: 500;
  font-size: 22px; }

.StatisticsBox_bigNumberInfo__1BoGL {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  text-align: center; }

.InfrastructureStatisticsBox_wrapper__8bjOO {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-height: calc(100% - 70px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width 0.5s ease, height 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @media screen and (max-width: 620px) {
    .InfrastructureStatisticsBox_wrapper__8bjOO {
      width: calc(100% - 30px);
      -webkit-transform: translate(16px, 30px);
              transform: translate(16px, 30px);
      max-height: calc(100% - 156px); } }

.InfrastructureStatisticsBox_wrapperIsHidden__gX8Pk {
  display: none;
  visibility: hidden; }

.InfrastructureStatisticsBox_header__ywkxh {
  position: relative;
  padding: 16px; }

.InfrastructureStatisticsBox_title__2SD3y {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.InfrastructureStatisticsBox_closeButton__3I1E5 {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 3px; }
  .InfrastructureStatisticsBox_closeButton__3I1E5:hover svg {
    fill: #8E8E8E; }
  .InfrastructureStatisticsBox_closeButton__3I1E5 svg {
    font-size: 26px;
    fill: #A2A2A2;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: fill 200ms ease-in-out, -webkit-transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease, -webkit-transform .5s ease; }

.InfrastructureStatisticsBox_closeButtonCollapse__MiryW svg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.InfrastructureStatisticsBox_collapseWrapper__2EFKc {
  overflow: auto; }

.InfrastructureStatisticsBox_content__34aXz {
  position: relative;
  overflow-y: auto;
  padding: 4px 16px 16px; }

.InfrastructureStatisticsBox_section__3JilG {
  margin-bottom: 32px; }
  .InfrastructureStatisticsBox_section__3JilG:nth-child(2) {
    margin-bottom: 24px; }
  .InfrastructureStatisticsBox_section__3JilG:last-child {
    margin-bottom: 0; }

.InfrastructureStatisticsBox_secondaryTitle__1xIGV {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  color: #555; }
  .InfrastructureStatisticsBox_secondaryTitle__1xIGV > small {
    display: block;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #616161; }
  .InfrastructureStatisticsBox_secondaryTitle__1xIGV svg {
    position: relative;
    top: 3px; }

.InfrastructureStatisticsBox_secondaryTitleExtraInfo__U25db {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: #717171; }

.InfrastructureStatisticsBox_infoList__1-AKt {
  display: flex;
  padding-left: 0;
  padding-bottom: 8px;
  margin-top: 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #DEDEDE; }

.InfrastructureStatisticsBox_infoListItem__3UFWg {
  display: flex;
  flex-direction: column;
  margin-right: 42px; }

.InfrastructureStatisticsBox_infoListItemValue__2KmqC {
  margin-bottom: 4px;
  font-size: 14px; }
  .InfrastructureStatisticsBox_infoListItemValue__2KmqC b {
    font-weight: 500; }
  .InfrastructureStatisticsBox_infoListItemValue__2KmqC small {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400; }

.InfrastructureStatisticsBox_infoListItemLabel__1Cnkw {
  font-size: 12px;
  font-weight: 400; }

.InfrastructureStatisticsBox_progressWrapper__26yBD {
  margin-bottom: 16px; }

.InfrastructureStatisticsBox_actionWrapper__2YIbw {
  display: flex;
  justify-content: center;
  margin-top: -16px; }

.InfrastructureStatisticsBox_actionButton__mAyXR svg {
  font-size: 20px;
  margin-right: 8px; }

.InfrastructureStatisticsBox_infoBox__1y-_N {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #d6d8db;
  border-radius: 4px;
  color: #383d41;
  background-color: #e2e3e5; }
  .InfrastructureStatisticsBox_infoBox__1y-_N p {
    margin: 0;
    font-size: 12px;
    line-height: 1.4; }

.StatisticsDataTableBox_wrapper__3gmaA {
  position: absolute;
  top: 16px;
  left: 436px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: calc(100% - 456px - 382px);
  max-height: calc(100% - 119px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width 0.5s ease, height 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 0.5s ease !important;
  -webkit-transform: translateX(16px);
          transform: translateX(16px); }
  @media screen and (max-width: 1200px) {
    .StatisticsDataTableBox_wrapper__3gmaA {
      display: none; } }

.StatisticsDataTableBox_header__dEJtq {
  position: relative;
  padding: 16px; }

.StatisticsDataTableBox_title__btjJt {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d; }

.StatisticsDataTableBox_closeButton__2C3C7 {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px; }
  .StatisticsDataTableBox_closeButton__2C3C7:hover svg {
    fill: #8E8E8E; }
  .StatisticsDataTableBox_closeButton__2C3C7 svg {
    font-size: 20px;
    fill: #A2A2A2;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: fill 200ms ease-in-out, -webkit-transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease;
    transition: fill 200ms ease-in-out, transform .5s ease, -webkit-transform .5s ease; }

.StatisticsDataTableBox_closeButtonCollapse__1R8jR svg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.StatisticsDataTableBox_collapseWrapper__2m_i- {
  overflow: scroll; }

.StatisticsDataTableBox_content__32F6f {
  position: relative;
  overflow-y: scroll;
  padding: 4px 16px 16px; }

.StatisticsDataTableBox_dataTableWrapper__2ddGn {
  margin-top: 12px;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #EBEAEF; }
  .StatisticsDataTableBox_dataTableWrapper__2ddGn:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }

.StatisticsDataTableBox_tableHeader__32aIl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }

.StatisticsDataTableBox_tableTitle__2m9Nd {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #555; }
  .StatisticsDataTableBox_tableTitle__2m9Nd b {
    font-weight: 500; }
  .StatisticsDataTableBox_tableTitle__2m9Nd > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px; }

.StatisticsDataTableBox_csvDownloadLink__2pLbL {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFF;
  background-color: #777;
  border-radius: 4px;
  transition: all 250ms ease-in-out; }
  .StatisticsDataTableBox_csvDownloadLink__2pLbL svg {
    font-size: 20px;
    margin-right: 10px; }
  @media screen and (max-width: 480px) {
    .StatisticsDataTableBox_csvDownloadLink__2pLbL {
      display: none; } }

.LandingPage_dialogWrapper__2Fgjv {
  position: relative; }

.LandingPage_closeButton__d9VIM {
  position: absolute;
  top: -10px;
  right: -10px; }

.LandingPage_alertText__3rN40 {
  font-size: 12px;
  text-align: center; }

.LandingPage_tempDialog__NcfAt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px; }
  .LandingPage_tempDialog__NcfAt p {
    margin-top: 0; }
  .LandingPage_tempDialog__NcfAt button {
    margin-top: 16px; }

.MapBiomasCreatePassword_wrapper__NQZv5 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }

.MapBiomasCreatePassword_content__2Qto- {
  flex: 1 1; }

.MapBiomasCreatePassword_logo__WIGAY {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto; }

.MapBiomasCreatePassword_box__3alGb {
  display: block;
  margin: 0 auto;
  width: 623px;
  height: 667px;
  margin-bottom: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.MapBiomasCreatePassword_form__11PQF .MapBiomasCreatePassword_MuiInputBase-root__348iE {
  background: rgba(96, 171, 62, 0.03) !important; }

.MapBiomasCreatePassword_contentWrapper__194NU {
  padding: 40px 108px 20px; }

.MapBiomasCreatePassword_title__psJzj {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center; }

.MapBiomasCreatePassword_subtitle__2BKeP {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px; }

.MapBiomasCreatePassword_formControl__ky0wT {
  margin-bottom: 24px; }

.MapBiomasCreatePassword_actionsWrapper__Hh_u7 {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 36px; }

.MapBiomasCreatePassword_button__3eHBB {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  height: 33px;
  background: #16A085;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px; }

.MapBiomasCreatePassword_createAcountLegend__1blVw {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: '17.16px';
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38); }

.MapBiomasCreatePassword_actionsWrapper__Hh_u7 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px; }

.MapBiomasCreatePassword_helperLink__3MgY5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #16A085;
  text-decoration: underline; }
  .MapBiomasCreatePassword_helperLink__3MgY5:hover {
    cursor: pointer;
    color: #015f4c; }

.FormFooter_wrapper__VUzlm {
  display: flex;
  justify-content: center;
  padding: 0 66px 23px; }

.FormFooter_helperLink__DMiNk {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  color: #616161; }
  .FormFooter_helperLink__DMiNk:hover {
    color: #413f3f; }

.PageFooter_wrapper__3X6EO {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466; }
  @media screen and (max-width: 480px) {
    .PageFooter_wrapper__3X6EO {
      padding: 0 6px; } }

.PageFooter_versionText__oqXsU {
  font-size: 12px;
  margin-right: 5px;
  color: white; }
  @media screen and (max-width: 768px) {
    .PageFooter_versionText__oqXsU {
      font-size: 10px; } }
  @media screen and (max-width: 480px) {
    .PageFooter_versionText__oqXsU {
      font-size: 9px; } }

.PageFooter_copyText__3PnkL {
  font-size: 12px;
  color: white; }
  @media screen and (max-width: 768px) {
    .PageFooter_copyText__3PnkL {
      font-size: 10px; } }
  @media screen and (max-width: 480px) {
    .PageFooter_copyText__3PnkL {
      font-size: 9px; } }

.PageFooter_alertText__10g-N {
  font-size: 10px;
  color: white; }
  @media screen and (max-width: 768px) {
    .PageFooter_alertText__10g-N {
      display: none; } }

.PageFooter_ecostageImage__19Zcu {
  display: block;
  max-width: 86px;
  height: auto; }
  @media screen and (max-width: 768px) {
    .PageFooter_ecostageImage__19Zcu {
      max-width: 72px; } }
  @media screen and (max-width: 480px) {
    .PageFooter_ecostageImage__19Zcu {
      max-width: 60px; } }

@media print {
  .PageFooter_wrapper__3X6EO {
    display: none; } }

.MapBiomasLogin_wrapper__1FUB5 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }

.MapBiomasLogin_content__19wh6 {
  flex: 1 1; }

.MapBiomasLogin_logo__5VMyt {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto; }

.MapBiomasLogin_box__-9-Hv {
  display: block;
  margin: 0 auto;
  width: 623px;
  height: 667px;
  margin-bottom: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.MapBiomasLogin_form__1SLNp .MapBiomasLogin_MuiInputBase-root__2XY_I {
  background: rgba(96, 171, 62, 0.03) !important; }

.MapBiomasLogin_contentWrapper__2tJPG {
  padding: 40px 108px 20px; }

.MapBiomasLogin_title__6yiwI {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center; }

.MapBiomasLogin_subtitle__2BivC {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px; }

.MapBiomasLogin_formControl__5EjXx.MapBiomasLogin_emailField__2Xafq {
  margin-bottom: 24px; }

.MapBiomasLogin_actionsWrapper__20rgd {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 36px; }

.MapBiomasLogin_button__14H6n {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  height: 33px;
  background: #16A085;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px; }

.MapBiomasLogin_createAcountLegend__3u865 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: '17.16px';
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38); }

.MapBiomasLogin_actionsWrapper__20rgd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px; }

.MapBiomasLogin_helperLink__3-iIW {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #16A085;
  text-decoration: underline; }
  .MapBiomasLogin_helperLink__3-iIW:hover {
    cursor: pointer;
    color: #015f4c; }

.MapBiomasPasswordRecovery_wrapper__2wGFc {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }

.MapBiomasPasswordRecovery_content__3lac8 {
  flex: 1 1; }

.MapBiomasPasswordRecovery_logo__1_c95 {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto; }

.MapBiomasPasswordRecovery_box__3qXGT {
  display: block;
  margin: 0 auto;
  width: 623px;
  height: 667px;
  margin-bottom: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.MapBiomasPasswordRecovery_contentWrapper__2OQ2Y {
  padding: 40px 108px 20px; }

.MapBiomasPasswordRecovery_title__2_UIe {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center;
  letter-spacing: 0.25px; }

.MapBiomasPasswordRecovery_subtitle__1QPPc {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px; }

.MapBiomasPasswordRecovery_formControl__2o5_c {
  margin-top: 12px;
  margin-bottom: 24px; }

.MapBiomasPasswordRecovery_actionsWrapper__JjQ-q {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 20px; }

.MapBiomasPasswordRecovery_footer__GI8xk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466; }
  @media screen and (max-width: 768px) {
    .MapBiomasPasswordRecovery_footer__GI8xk {
      justify-content: center; } }

.MapBiomasPasswordRecovery_copyText__1Kn7O {
  font-size: 12px;
  color: white; }
  @media screen and (max-width: 768px) {
    .MapBiomasPasswordRecovery_copyText__1Kn7O {
      font-size: 10px; } }

.MapBiomasPasswordRecovery_actionsWrapper__JjQ-q {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px; }

.MapBiomasPasswordRecovery_accessAccountLegend__1qvmS {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 42px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38); }

.MapBiomasPasswordRecovery_helperLink__1QYxQ {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #16A085;
  text-decoration: underline; }
  .MapBiomasPasswordRecovery_helperLink__1QYxQ:hover {
    cursor: pointer;
    color: #015f4c; }

.MapBiomasPasswordRecovery_button__2ykXT {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 6px; }

.MapbiomasCreateAccount_wrapper__T1zJA {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }

.MapbiomasCreateAccount_content__zyNUn {
  flex: 1 1; }

.MapbiomasCreateAccount_logo__37YTF {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto; }

.MapbiomasCreateAccount_box__3gqjf {
  display: block;
  margin: 0 auto;
  width: 623px;
  margin-bottom: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.MapbiomasCreateAccount_form__BBx4d .MapbiomasCreateAccount_MuiInputBase-root__2rCR6 {
  background: rgba(96, 171, 62, 0.03) !important; }

.MapbiomasCreateAccount_contentWrapper__1W9ge {
  padding: 40px 108px 20px; }

.MapbiomasCreateAccount_title__3pByc {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center; }

.MapbiomasCreateAccount_subtitle__d-rXG {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px; }

.MapbiomasCreateAccount_formControl__-03up.MapbiomasCreateAccount_mb__2Es1d {
  margin-bottom: 24px !important; }

.MapbiomasCreateAccount_button__3YAXh {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  height: 33px;
  background: #16A085;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px; }

.MapbiomasCreateAccount_createAcountLegend__13Wto {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: '17.16px';
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38); }

.MapbiomasCreateAccount_actionsWrapper__16Waz {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 36px; }

.MapbiomasCreateAccount_helperLink__1iM0V {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #16A085;
  text-decoration: underline;
  margin-bottom: 66px;
  display: block; }
  .MapbiomasCreateAccount_helperLink__1iM0V:hover {
    cursor: pointer;
    color: #015f4c; }

.MapbiomasCreateAccount_textArea__3tYaN {
  margin-top: 14px;
  margin-bottom: 0 !important; }
  .MapbiomasCreateAccount_textArea__3tYaN label {
    top: -14px; }
  .MapbiomasCreateAccount_textArea__3tYaN textarea {
    min-height: 111px; }

.MapbiomasCreateAccount_privacyLink__1vwOJ {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  color: #404040;
  text-decoration: underline; }

.MapbiomasCreateAccount_privacyCheck__2Bh_t {
  color: #16a085; }

.MapbiomasCreateAccount_privacyLabel__1KTKQ {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  color: #404040; }

.TerritoryDashboard_wrapper__BEtBZ {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Lato', sans-serif; }

.TerritoryDashboard_content__1-DD3 {
  flex: 1 1; }

.TerritoryDashboard_logo__yQRGp {
  width: 334px;
  height: 67px;
  display: block;
  margin: 80px auto 60px auto; }

.TerritoryDashboard_contentBox__K9_MO {
  display: block;
  width: 820px;
  margin: 60px auto; }

.TerritoryDashboard_formBox__2Hjf0 {
  display: block;
  width: 480px;
  padding: 20px;
  margin: 0 auto; }

.TerritoryDashboard_contentWrapper__3SkZl {
  padding: 40px 0; }

.TerritoryDashboard_contentTitleWrapper__1c-6S {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 420px;
  margin: 0 auto; }
  .TerritoryDashboard_contentTitleWrapper__1c-6S p {
    margin-top: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    color: #808283; }

.TerritoryDashboard_contentTitle__k1mBi {
  font-size: 28px;
  font-weight: 400; }
  .TerritoryDashboard_contentTitle__k1mBi b {
    font-weight: 700; }

.TerritoryDashboard_imageBox__9CM5o {
  position: relative; }
  .TerritoryDashboard_imageBox__9CM5o img:last-child {
    position: absolute;
    top: 0;
    left: 0; }

.TerritoryDashboard_moduleContentWrapper__3oze5 {
  position: relative;
  padding: 0 40px; }
  .TerritoryDashboard_moduleContentWrapper__3oze5 .TerritoryDashboard_imageBox__9CM5o {
    position: absolute;
    top: 0;
    left: 100px; }

.TerritoryDashboard_sectionTitleGrid__RpFIa {
  margin-bottom: 48px;
  margin-top: 60px; }

.TerritoryDashboard_sectionTitleBox__1I7ux {
  display: flex;
  align-items: center; }

.TerritoryDashboard_sectionTitleBoxTitle__-cWzy {
  max-width: 180px;
  margin: 0;
  line-height: 1.4;
  font-size: 26px;
  font-weight: 900; }

.TerritoryDashboard_sectionTitleBoxSubtitle__2-yCx {
  margin: 8px 0 0;
  font-size: 18px;
  font-weight: 700;
  color: #555; }

.TerritoryDashboard_sectionTitleBoxInfo__3CRIo {
  margin-right: 40px;
  font-size: 16px;
  line-height: 1.4;
  color: #808283; }

.TerritoryDashboard_triangleArrow__11jfJ {
  width: 0;
  height: 0;
  border-top: 60px solid transparent !important;
  border-bottom: 60px solid transparent !important;
  border-left: 60px solid green;
  margin-right: 32px; }

.TerritoryDashboard_yearTitle__2ZHrs {
  font-size: 18px;
  font-weight: 900;
  text-align: center; }

.TerritoryDashboard_yearBlockImage__1zijy {
  align-items: center;
  display: flex;
  flex-direction: column; }

.TerritoryDashboard_title__LFE62 {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 34px;
  font-weight: 600;
  line-height: 41.99px;
  color: #404040;
  text-align: center;
  letter-spacing: 0.25px; }

.TerritoryDashboard_subtitle__3WhyQ {
  text-align: center;
  margin-bottom: 48px;
  color: #646464;
  font-size: 16px; }

.TerritoryDashboard_formControl__1razK {
  margin-top: 12px;
  margin-bottom: 24px; }

.TerritoryDashboard_actionsWrapper__39uWc {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 20px; }

.TerritoryDashboard_footer__yao2f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466; }
  @media screen and (max-width: 768px) {
    .TerritoryDashboard_footer__yao2f {
      justify-content: center; } }

.TerritoryDashboard_copyText__2vYdg {
  font-size: 12px;
  color: white; }
  @media screen and (max-width: 768px) {
    .TerritoryDashboard_copyText__2vYdg {
      font-size: 10px; } }

.TerritoryDashboard_actionsWrapper__39uWc {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px; }

.TerritoryDashboard_button__UoIiE {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 6px; }

.TerritoryDashboard_section__1GwoO {
  width: 100%;
  margin-bottom: 32px; }
  .TerritoryDashboard_section__1GwoO:last-child {
    margin-bottom: 0; }

.TerritoryDashboard_secondaryTitle__21EJH {
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 700;
  color: #555;
  text-align: center; }
  .TerritoryDashboard_secondaryTitle__21EJH > small {
    display: block;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #616161; }
  .TerritoryDashboard_secondaryTitle__21EJH svg {
    position: relative;
    top: 3px; }

.TerritoryDashboard_secondaryTitleExtraInfo__2YOl5 {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: #717171; }

.TerritoryDashboard_progressWrapper__3E-NP {
  margin-bottom: 16px; }

.TerritoryDashboard_actionWrapper__13QUZ {
  display: flex;
  justify-content: center;
  margin-top: -16px; }

.TerritoryDashboard_actionButton__2m8gm svg {
  font-size: 20px;
  margin-right: 8px; }

.TerritoryDashboard_infoBox__2RQOW {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #d6d8db;
  border-radius: 4px;
  color: #383d41;
  background-color: #e2e3e5; }
  .TerritoryDashboard_infoBox__2RQOW p {
    margin: 0;
    font-size: 12px;
    line-height: 1.4; }

.TerritoryDashboard_modulesBlock__D9fsW {
  width: 100%;
  padding: 60px 40px 44px;
  background-color: #DCDD78;
  margin: 32px 0; }

.TerritoryDashboard_moduleBlock__2RfVO {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }

.TerritoryDashboard_moduleBlockIcon__cMej7 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin-right: 12px;
  background-color: white;
  border-radius: 4px; }
  .TerritoryDashboard_moduleBlockIcon__cMej7 svg {
    fill: #555; }

.TerritoryDashboard_moduleBlockTitle__l3QIU {
  font-size: 16px;
  font-weight: 700; }

.Accuracy_container__lWkBC {
  max-width: 900px;
  margin: 0 auto;
  font-size: 13px; }

.Accuracy_searchContainer__XRcM4 {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  padding: 30px;
  margin: 30px 0;
  background-color: #EEEEEE;
  border-radius: 2px; }
  .Accuracy_searchContainer__XRcM4 .Accuracy_formControl__2Wssu {
    flex: 2 1; }
  .Accuracy_searchContainer__XRcM4 .Accuracy_buttonContainer__nBxtW {
    display: flex;
    flex: 1 1;
    padding-top: 21px; }

.Accuracy_label__sLPx6 {
  display: block;
  margin-bottom: 5px;
  line-height: 16px;
  color: #7F8184; }

.Accuracy_select__7_s4r {
  width: 100%;
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 2px; }

.Accuracy_button__e3apE {
  flex: 1 1;
  padding: 10px;
  border: none;
  background-color: #84CAA0;
  text-transform: uppercase;
  color: white;
  border-radius: 2px; }

.Accuracy_cards__2mR94 {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 30px;
  text-align: center; }
  .Accuracy_cards__2mR94 span {
    display: block; }

.Accuracy_loadingContainer__2O9G_ {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 32px);
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000; }

.Accuracy_card__2nrt4 {
  flex: 1 1;
  padding: 30px 15px;
  border: 3px solid #EEEEEE;
  font-size: 12px; }

.Accuracy_cardData__16tj9 {
  font-weight: 700;
  font-size: 2.5rem; }

.Accuracy_lineCharts__2MoIX {
  display: flex;
  grid-gap: 30px;
  gap: 30px; }
  .Accuracy_lineCharts__2MoIX .Accuracy_chart__3YLwm {
    flex: 1 1; }
    .Accuracy_lineCharts__2MoIX .Accuracy_chart__3YLwm p {
      text-align: center;
      font-size: 10px; }

.Accuracy_barChart__3rJje {
  margin-top: 30px; }
  .Accuracy_barChart__3rJje > p {
    text-transform: uppercase;
    text-align: center;
    font-size: 16px; }

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Inter", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  box-sizing: border-box;
  color: #444;
}

*, *:before, *:after {
  font-family: inherit;
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none;
  list-style: none;
  text-decoration: none;
}

/* Custom styles */

.leaflet-bar {
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
}

.leaflet-bar a,
.leaflet-bar a:hover {
  width: 32px;
  height: 32px;
  line-height: 28px;
  color: #777777;
  font-size: 20px;
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
  border-top: 2px solid #1E74BB;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px -1px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
}

.leaflet-popup-tip {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px -1px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 10px;
  right: 10px;
}

.leaflet-right .leaflet-control-zoom.leaflet-bar.leaflet-control {
  position: relative;
  right: 6px;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .leaflet-right .leaflet-control-zoom.leaflet-bar.leaflet-control {
    right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .leaflet-right .leaflet-control-zoom.leaflet-bar.leaflet-control {
    display: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px -1px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  }

  .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
  }

  .leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  .leaflet-touch .leaflet-bar a {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
}

.leaflet-bar a,
.leaflet-bar a:hover {
  color: #555;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

/*
#box-zoom-button::after {
  content: '';
  visibility: hidden;
  position: absolute;
  top: 5px;
  left: -161px;
  max-width: 300px;
  padding: 4px 8px;
  font-family: 'Inter', sans-serif;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4em;
  word-wrap: break-word;
  color: #fff;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
}

#box-zoom-button:hover::after {
  content: 'Selecione uma área para zoom';
  visibility: visible;
}
*/

.locale-en #box-zoom-button:hover::after {
  content: 'Select zoom area';
  left: -109px;
}

